import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
	FlexboxRow,
	FlexboxColumn,
	FlexRowLeft,
	FlexColLeft,
	FlexRowRight
} from '../Layout';
import {
	FooterContainer,
	MainBlock,
	Copyright,
	SocialContainer,
	SocialLink,
	FooterLink
} from './styles';

import { IconContainer } from '../Common/icons';
import {FOOTER_SOCIAL_LINK} from "../../theme/colors";
import { FacebookIcon, TwitterIcon } from '../Common/icons';

export default class Footer extends Component {
	render() {
		return (
			<FooterContainer>
				<FlexboxRow justify={'space-between'}>
					<FlexColLeft>
						<FlexRowLeft>
							<FooterLink to={'/terms-of-service'}>Terms of Use</FooterLink>
							<FooterLink to={'/contact-us'}>Contact Us</FooterLink>
							<FooterLink to={'/gambling-aware'}>Gambling Aware</FooterLink>
							<FooterLink to={'/help'}>Help Center</FooterLink>
						</FlexRowLeft>
						<Copyright>
							©2016-2018 Dungeon Riches LLC. All Rights Reserved. | Server Time:
							2018-05-07 10:06:06
						</Copyright>
					</FlexColLeft>
					<FlexRowRight>
						<SocialContainer>
						<NavLink to={'https://www.facebook.com/Dungeon-Riches-2836903319867342/'} target="_blank">
							<IconContainer bgColor={FOOTER_SOCIAL_LINK} fontSize={24}>
								<FacebookIcon />
							</IconContainer>
						</NavLink>
						</SocialContainer>
					</FlexRowRight>
				</FlexboxRow>
			</FooterContainer>
		);
	}
}
