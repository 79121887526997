import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  walletInstalled: state.web3.walletInstalled,
  walletConnected: state.web3.walletConnected
});

const PrivateRoute = ({
  component: Component,
  path,
  walletInstalled,
  walletConnected
}) => (
  <Route
    path={path}
    render={props => {
      if (walletInstalled && walletConnected) {
        return <Component {...props} />;
      }
      if (!walletInstalled) {
        return (
          <Redirect
            to={{
              pathname: "/get-metamask",
              state: { from: props.location }
            }}
          />
        );
      }
      if (!walletConnected) {
        return (
          <Redirect
            to={{
              pathname: "/activate-metamask",
              state: { from: props.location }
            }}
          />
        );
      }
    }}
  />
);

export default connect(
  mapStateToProps,
  null
)(PrivateRoute);
