const LOAD_COMPLETE = "LOAD_COMPLETE";

const initialState = {
  resources: false,
  assetsLoaded: false
};

export const loadComplete = resources => ({
  type: LOAD_COMPLETE,
  payload: resources
});

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_COMPLETE: {
      return {
        ...state,
        assetsLoaded: true,
        resources: payload
      };
    }
    default: {
      return state;
    }
  }
};
