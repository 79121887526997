import Web3 from "web3";
import * as ethers from "ethers";
import { helpers } from "leap-core";
import { PLASMA_PROVIDER, TOKEN_ADDRESS } from "./const";
import TOKEN_ABI from "./contracts/erc20Abi";

const SET_BALANCE = "SET_BALANCE";
const SET_COLOR = "SET_COLOR";
const WALLET_ACTIVATION_START = "WALLET_ACTIVATION_START";
const WALLET_ACTIVATION_SUCCESS = "WALLET_ACTIVATION_SUCCESS";
const WALLET_ACTIVATION_FAILED = "WALLET_ACTIVATION_FAILED";

const { ethereum } = window;
const plasma = helpers.extendWeb3(new Web3(PLASMA_PROVIDER));
const tokenContract = new plasma.eth.Contract(TOKEN_ABI, TOKEN_ADDRESS);

const rpc = new ethers.providers.JsonRpcProvider(PLASMA_PROVIDER);
const wallet = window.ethereum ? new Web3(window.ethereum) : null;
window.wallet = wallet;

const initialState = {
  balance: "--",
  account: null,
  wallet,
  walletInstalled: !!ethereum,
  walletConnected: false,
  walletActivationProcess: false,
  plasma,
  rpc,
  tokenColor: null
};

export const setColor = payload => ({
  type: SET_COLOR,
  payload
});

export const setBalance = payload => ({
  type: SET_BALANCE,
  payload
});

let balanceInterval = null;
export const startBalancePolling = account => async dispatch => {
  balanceInterval = setInterval(async () => {
    const balance = await tokenContract.methods.balanceOf(account).call();
    dispatch(setBalance(balance));
  }, 3000);
};
export const clearBalancePolling = () => {
  clearInterval(balanceInterval);
};

export const activateWallet = () => async dispatch => {
  console.log("Activate wallet");
  try {
    dispatch({
      type: WALLET_ACTIVATION_START
    });
    const accounts = await ethereum.enable();
    //accounts = await wallet.eth.getAccounts();
    dispatch({
      type: WALLET_ACTIVATION_SUCCESS,
      payload: accounts
    });

    const color = await plasma.getColor(TOKEN_ADDRESS);
    console.log("Got color:", color);

    dispatch(setColor(color));

    const [account] = accounts;
    const balance = await tokenContract.methods.balanceOf(account).call();
    console.log("Got Balance:", balance);
    dispatch(setBalance(balance));
  } catch (e) {
    dispatch({
      type: WALLET_ACTIVATION_FAILED,
      payload: e.message
    });
  }
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COLOR: {
      return {
        ...state,
        tokenColor: payload
      };
    }

    case SET_BALANCE: {
      return {
        ...state,
        balance: payload
      };
    }

    case WALLET_ACTIVATION_START: {
      return {
        ...state,
        walletActivationProcess: true
      };
    }

    case WALLET_ACTIVATION_SUCCESS: {
      // TODO: add check if there are more than 1 account and 0 accounts
      const account = payload[0];
      return {
        ...state,
        walletActivationProcess: false,
        walletConnected: true,
        account
      };
    }

    case WALLET_ACTIVATION_FAILED: {
      return {
        ...state,
        walletActivationProcess: false,
        walletConnected: false
      };
    }
    default: {
      return state;
    }
  }
};
