import * as PIXI from 'pixi.js'
import { Container, particles } from 'pixi.js';

const DECAY = 0.5,
	GRAVITY = 5;

export default class CoinEmitter extends Container {
	constructor(config, app) {
		super();

		this.app = app;
		this.width = app.width;
		this.height = app.height;

		app.addChild(this);

		this._accum = 0;
	}

	spawn(pos, damageVector, number) {
		console.log(`Spawn ${number}`);
		for (let i = 0; i < number; i++) {
			let texture = this.app.resources['coin'].texture;
			let sprite = new PIXI.Sprite.from(texture);
			sprite.position.x = pos.x + (Math.floor(Math.random() * 40) - 20);
			sprite.position.y = pos.y + (Math.floor(Math.random() * 40) - 20);
			sprite.alpha = 1;
			sprite.bump = 0;
			sprite.floor = 470 + Math.floor(Math.random() * 35);
			sprite.vector = {
				x: damageVector.x + 4 * (Math.random() - 0.5),
				y: damageVector.y - 10 * Math.random()
			};
			sprite.scale = {
				x: 0.6,
				y: 0.6
			};

			this.addChild(sprite);
		}
	}

	updateParticles(dt) {
		this._accum += dt;
		const { length } = this.children;
		const self = this;
		const _remove = [];
		for (let i = 0; i < length; i++) {
			let child = this.children[i];
			if (child.position.y > child.floor) {
				//_remove.push(child);
				if (child.bump === 0 && i % 2 === 0) {
					this.app._soundController.play('coinDrop');
				}
				child.bump += 1;
				child.vector.x = child.vector.x - 0.5;
				child.vector.y = -10 / child.bump;
				if (child.vector.y > -3 && !child.endAnimation) {
					child.endAnimation = true;
					_remove.push(child);
				}
			}
			if (!child.endAnimation) {
				child.vector.y = child.vector.y + 0.5;
				child.position.x = child.position.x + child.vector.x;
				child.position.y = child.position.y + child.vector.y;
			}
		}

		// Clear unused particles
		for (let j = 0; j < _remove.length; j++) {
			const coin = _remove[j];
			this.app.balanceController.suckCoin(coin, () => {
				self.removeChild(coin);
			});
		}
	}
}
