import * as PIXI from "pixi.js";
import { TimelineMax, Power2 } from "gsap";
import { Container } from "pixi.js";
import { spine } from "pixi-spine";
import AssetLoader from "../components/Loader";
import CoinEmitter from "../components/particles/CoinEmitter";
import CombatPlayer from "../components/CombatPlayer";
import LifeBar from "../components/LifeBar";
import Customizer from "../components/Customizer";
import CardGameUI from "../ui/CardGameUI";
import {
  bgAssets,
  cardAssets,
  effectsAssets,
  enemyAssets,
  heroAssets,
  uiAssets
} from "../components/Loader/resources";
import { sounds } from "../components/SoundController/resources";
import SoundController from "../components/SoundController/index";
import Balance from "../ui/Balance";
import Camera from "../components/Camera";
//import CardGameUI from "../ui/CardGameUI/index";
import { mockMap } from "./TestInfiniteDungeon";
import InfiniteLevel from "../level/InfiniteLevel";
import { mockDungeonEnemies } from "./mockEnemies";

export default class FightScene extends Container {
  constructor(app, playerConfig, resources) {
    super();
    this.app = app;
    this.ready = false;
    this.playerConfig = playerConfig;
    this.resources = resources;

    //this.initBackground();
    this.initLevelBackground();
    this.initMainPlane();
    this.initForeground();
    this.initEmitters();

    this.initSounds();
    this.initCombat();
    this.initUI();
    /*
    this.initLifeBars();
    this.initLayout();
    this.initLifeBars();
    */
  }

  initBackground() {
    this.bg = new Container();
    const { resources } = this;
    let groundTexture = resources["ground-tile"].texture;
    const { width, height } = this.app._getDimensions();
    let ground = new PIXI.extras.TilingSprite(groundTexture, width, 300);
    this.bg.addChild(ground);
    ground.position.x = 0;
    ground.position.y = height - 300;

    let wallTexture = resources["wall-tile"].texture;
    console.log(wallTexture);
    let wall = new PIXI.extras.TilingSprite(wallTexture, width, height);
    this.bg.addChild(wall);
    wall.position.x = 0;
    wall.position.y = -300;

    let doorTexture = resources["door"].texture;
    let door = new PIXI.Sprite.from(doorTexture);
    this.bg.addChild(door);
    door.scale.x = 0.6;
    door.scale.y = 0.6;
    door.position.x = 400;
    door.position.y = 50;

    let columnTexture = resources["column"].texture;
    let columnFirst = new PIXI.Sprite.from(columnTexture);
    this.bg.addChild(columnFirst);
    columnFirst.scale.x = 0.6;
    columnFirst.scale.y = 0.6;
    columnFirst.position.x = 110;
    columnFirst.position.y = -52;

    let columnSecond = new PIXI.Sprite.from(columnTexture);
    this.bg.addChild(columnSecond);
    columnSecond.scale.x = 0.6;
    columnSecond.scale.y = 0.6;
    columnSecond.position.x = 920;
    columnSecond.position.y = -50;

    let torchFirst = new PIXI.spine.Spine(resources.torch.spineData);
    torchFirst.scale.x = 0.6;
    torchFirst.scale.y = 0.6;
    torchFirst.position.x = 235;
    torchFirst.position.y = 290;

    let torchSecond = new PIXI.spine.Spine(resources.torch.spineData);
    torchSecond.scale.x = 0.6;
    torchSecond.scale.y = 0.6;
    torchSecond.position.x = 1050;
    torchSecond.position.y = 290;

    torchFirst.state.setAnimation(1, "animation", true);
    torchSecond.state.setAnimation(1, "animation", true);

    this.bg.addChild(torchFirst);
    this.bg.addChild(torchSecond);

    torchFirst.tint = 0xdddddd;
    torchSecond.tint = 0xdddddd;
    this.addChild(this.bg);
    this.camera = new Camera(this.bg);
  }
  initLevelBackground() {
    const dimensions = this.app._getDimensions();
    this._level = new InfiniteLevel(this.app, dimensions, mockMap);
  }
  initMainPlane() {
    const { resources } = this;
    this.hero = new PIXI.spine.Spine(resources.girl.spineData);
    this.hero.autoUpdate = false;
    this.hero.skeleton.setToSetupPose();
    this.hero.update();
    //this.addChild(this.hero);

    this.swordEffect = new PIXI.spine.Spine(resources.swish.spineData);
    this.swordEffect.skeleton.setToSetupPose();
    this.swordEffect.visible = 0;
    this.swordEffect.position.x = 740;
    this.swordEffect.position.y = 680;
    //this.addChild(this.swordEffect);
    this.hero.swordEffect = this.swordEffect;

    // run
    if (this.hero.state.hasAnimation("idle")) {
      // run forever
      this.hero.state.setAnimation(1, "idle", true);
      this.hero.state.timeScale = 1;
    }

    this.hero.position.x = 300;
    this.hero.position.y = 535;
    this.hero.scale.x = 0.7;
    this.hero.scale.y = 0.7;

    // Init Enemy
    let enemy = new PIXI.spine.Spine(resources.skelet.spineData);
    this.enemy = enemy;
    this.enemy.autoUpdate = false;
    this.enemy.skeleton.setToSetupPose();
    this.enemy.update();
    //this.addChild(enemy);

    if (enemy.state.hasAnimation("idle")) {
      // run forever
      enemy.state.setAnimation(1, "idle", true);
      enemy.state.timeScale = 1;
    }

    enemy.position.x = 1000;
    enemy.position.y = 530;
    enemy.scale.x = 0.2;
    enemy.scale.y = 0.2;
    //enemy.scale.x = 0.2;
    //enemy.scale.y = 0.2;

    this.cameraPlan = new Container();
    this.cameraPlan.addChild(this._level);
    this.cameraPlan.addChild(this.hero);
    //this.cameraPlan.addChild(this.enemy);
    this.addChild(this.cameraPlan);
    this.camera = new Camera(this.cameraPlan);
    Customizer.applyConfig(this.hero, this.playerConfig);

    //window.enemy = enemy;
    window.hero = this.hero;
    window.fightScene = this;
    this.ready = true;
    this.animateEntrance();
  }
  initForeground() {
    const { resources } = this;

    this.deflectEffect = new PIXI.spine.Spine(resources.deflect.spineData);
    this.deflectEffect.autoUpdate = false;
    this.deflectEffect.skeleton.setToSetupPose();
    this.deflectEffect.update();
    this.addChild(this.deflectEffect);
    this.deflectEffect.alpha = 0;
  }

  initEmitters() {
    this._coinEmitter = new CoinEmitter({}, this);
    this.cameraPlan.addChild(this._coinEmitter);
  }
  initSounds() {
    this._soundController = new SoundController(sounds);
    //this._soundController.play('bgMusic', 0.25);
  }
  initCombat() {
    const { hero, enemy, camera, _soundController } = this;
    this._combatPlayer = new CombatPlayer(
      this,
      hero,
      enemy,
      event => this.onEvent(event),
      camera,
      _soundController
    );
  }

  initLayout() {
    console.log("Init layout!");
    console.log(this._combatPlayer);
    const { _getDimensions, api } = this.app;
    this._uiBottomContainer = new CardGameUI(
      this,
      api,
      _getDimensions(),
      event => this.onEvent(event)
    );
    this.addChild(this._uiBottomContainer);
  }
  initLifeBars() {
    this._enemyIndex = 0;
    const currentEnemy = mockDungeonEnemies[this._enemyIndex];
    this.playerLifeBar = new LifeBar(
      {
        name: "Player",
        color: "green",
        width: 150,
        height: 20,
        totalHP: 1200,
        currentHP: 1200
      },
      this
    );
    this.enemyLifeBar = new LifeBar(
      {
        name: currentEnemy.name,
        color: "red",
        width: 150,
        height: 20,
        totalHP: currentEnemy.hp,
        currentHP: currentEnemy.hp
      },
      this
    );

    this.playerLifeBar.position = {
      x: 350,
      y: 100
    };
    this.enemyLifeBar.position = {
      x: 770,
      y: 100
    };

    this.addChild(this.playerLifeBar);
    this.addChild(this.enemyLifeBar);
  }

  initUI() {
    const { balance = 0 } = this.app;
    this.balanceController = new Balance(
      {
        resources: this.resources,
        value: balance
      },
      this
    );
    this.addChild(this.balanceController);
  }

  getBetSize() {
    return Math.max(
      1,
      Math.floor(this._uiBottomContainer.betController.betSize)
    );
  }
  getAssets() {
    return {
      ...this.resources,
      ...this.app.getAssets()
    };
  }
  getSoundController() {
    return this.app.getSoundController();
  }
  _killEnemy() {
    console.log(this._level.mapPosition);
    const movie = new TimelineMax();
    const { hero, enemy, _level } = this;
    const travelTime = 3;

    const distanceToNext = 3000;
    const newMapPosition = this._level.mapPosition + distanceToNext;

    console.log(this._level.mapPosition, newMapPosition);

    this._level._mapStatic.addChild(enemy);

    const stopLength = hero.state.data.skeletonData.findAnimation(
      "run-ninja-stop"
    ).duration;

    const enemyDeathOffset = 1.5;
    movie
      .add(() => {
        this._combatPlayer.enemyDeath();
      })
      .add(() => {
        hero.state.setAnimation(1, "run-ninja", true);
      }, enemyDeathOffset)
      .add(() => {
        hero.state.setAnimation(1, "run-ninja-stop", false);
      }, travelTime + enemyDeathOffset)
      .add(() => {
        hero.state.setAnimation(1, "idle", true);
      }, travelTime + stopLength + enemyDeathOffset)
      .to(_level, travelTime, { mapPosition: newMapPosition }, enemyDeathOffset)
      .add(() => {
        enemy.mapPosition.x = newMapPosition + 1000;
        enemy.state.setAnimation(1, "idle", true);
      }, enemyDeathOffset + travelTime - 1.5);
  }

  _pickUpEnemy() {
    this.enemy.state.setAnimation(1, "idle", true);
  }

  _runToNext() {
    console.log(this._level.mapPosition);
    const movie = new TimelineMax();
    const { hero, enemy, _level } = this;
    const travelTime = 3;

    const distanceToNext = 3000;
    const newMapPosition = this._level.mapPosition + distanceToNext;

    console.log(this._level.mapPosition, newMapPosition);

    this._level._mapStatic.addChild(enemy);

    const stopLength = hero.state.data.skeletonData.findAnimation(
      "run-ninja-stop"
    ).duration;

    movie
      .add(() => {
        hero.state.setAnimation(1, "run-ninja", true);
      }, 0)
      .add(() => {
        hero.state.setAnimation(1, "run-ninja-stop", false);
      }, travelTime)
      .add(() => {
        hero.state.setAnimation(1, "idle", true);
        this._uiBottomContainer.resetGame();
      }, travelTime + stopLength)
      .to(_level, travelTime, { mapPosition: newMapPosition }, 0)
      .add(() => {
        this._enemyIndex += 1;
        if (this._enemyIndex >= mockDungeonEnemies.length) {
          this._enemyIndex = 0;
        }
        const currentEnemy = mockDungeonEnemies[this._enemyIndex];
        console.log(JSON.stringify(currentEnemy));

        enemy.mapPosition.x = newMapPosition + 1000;
        enemy.state.setAnimation(1, "idle", true);
        enemy.state.timeScale = 1;

        this.removeChild(this.enemyLifeBar);
        this.enemyLifeBar = new LifeBar(
          {
            name: currentEnemy.name,
            color: "red",
            width: 150,
            height: 20,
            totalHP: currentEnemy.hp,
            currentHP: currentEnemy.hp
          },
          this
        );
        this.addChild(this.enemyLifeBar);
        this.enemyLifeBar.position = {
          x: 770,
          y: 100
        };
      }, travelTime - 1);
  }

  onEvent(event) {
    const { type } = event;
    console.log(event);
    switch (type) {
      case "spawn-coins": {
        const { position, vector, damage } = event;
        const number = Math.max(
          1,
          Math.floor((this.getBetSize() * damage) / 20)
        );
        console.log(number);
        this._coinEmitter.spawn(position, vector, number);
        break;
      }
      case "deflect": {
        const { position } = event;
        this.deflectEffect.alpha = 1;
        this.deflectEffect.position = position;
        this.deflectEffect.state.setAnimation(1, "flash", false);
        break;
      }

      case "mockDamage": {
        const { target, value } = event;
        /*
        this.enemyLifeBar._turnDamage += value;
        if (this.enemyLifeBar.currentHP < this.enemyLifeBar._turnDamage) {
          this.enemy.shouldDie = true;
        }
*/
        break;
      }

      case "damage": {
        const { target, value } = event;
        /*        if (target === "player") {
          this.playerLifeBar.currentHP -= value;
        } else {
          const newValue = this.enemyLifeBar.currentHP - value;

          if (newValue < 0) {
            console.log("DIE");
            this.enemyLifeBar.currentHP = 0;
            this.enemy.dead = true;
          } else {
            this.enemyLifeBar.currentHP = newValue;
          }
        }*/
        break;
      }
      case "place-bet": {
        const { value } = event;
        //console.log(`New bet => ${value}`);
        // this.balanceController.reduceCoins(value);
        break;
      }
      default:
        break;
    }
  }

  checkGameState() {
    /*    if (this.enemyLifeBar.currentHP === 0) {
      this._combatPlayer.enemyDeath();
      this._runToNext();
    } else {
      this._uiBottomContainer.resetGame();
    }*/
    this._uiBottomContainer.resetGame();
  }

  animateEntrance() {
    const movie = new TimelineMax();
    const { hero, enemy, _level } = this;
    const travelTime = 2;
    const characterEntry = 1;

    const travelDistance = 4300;

    this._level._mapStatic.addChild(enemy);
    enemy.mapPosition = {
      x: 1000 + travelDistance
    };

    const stopLength = hero.state.data.skeletonData.findAnimation(
      "run-ninja-stop"
    ).duration;

    const mapOffset = travelDistance;

    movie
      .set(hero.position, { x: -300 })
      .set(_level, { mapPosition: 0 })
      .add(() => {
        hero.state.setAnimation(1, "run-ninja", true);
      }, 0)
      .to(hero.position, travelTime - characterEntry, { x: 150 }, 0)
      .to(
        hero.position,
        travelTime - characterEntry,
        { ease: Power2.easeOut, x: 200 },
        travelTime - characterEntry
      )
      .to(_level, travelTime, { mapPosition: mapOffset }, 0)
      .add(() => {
        hero.state.setAnimation(1, "run-ninja-stop", false);
      }, travelTime - characterEntry)
      .add(() => {
        hero.state.setAnimation(1, "idle", true);
        this.initLayout();
        //this.initLifeBars();
        //this.initUI();
        this.app.addUI();
      }, travelTime - characterEntry + stopLength);
  }

  update(dt) {
    if (this.ready) {
      //this.hero.update(dt);
      this._coinEmitter.updateParticles(dt);
      this._combatPlayer.update(dt);
      this.deflectEffect.update(dt);
      this._level.update(dt);
    }
  }
}
