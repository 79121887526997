import Web3 from "web3";
import { ethers, utils } from "ethers";
import { Tx, Outpoint, Output, Input, helpers } from "leap-core";
import { ROUND_SERVER as API } from "../../../config";

const web3 = new Web3();
const getCardShortName = card => {
  switch (card) {
    case "Attack": {
      return "A1";
    }
    case "Strong": {
      return "A2";
    }
    case "Defense": {
      return "D1";
    }
    case "Mana": {
      return "M1";
    }
    case "Void": {
      return "V0";
    }
    default: {
      return "V0"
    }
  }
};

const promisify = (fun, params = []) => {
  return new Promise((resolve, reject) => {
    fun(...params, (err, data) => {
      if (err !== null) reject(err);
      else resolve(data);
    });
  });
};

let roundLock;

export default class LeapAPI {
  constructor(props) {
    const { account, plasma, wallet } = props;
    this.address = account;
    // Init APi
    console.log("Leap API is instantiated!");
    this.plasma = plasma;
    this.wallet = wallet;
    this.fetchCards = this.fetchCards.bind(this);
    this.submitHand = this.submitHand.bind(this);
    this.signTransaction = this.signTransaction.bind(this);
    this.pollServerForResponse = this.pollServerForResponse.bind(this);

    this.roundLockABI = [
      {
        constant: true,
        inputs: [
          {
            name: "_hand",
            type: "bytes32"
          }
        ],
        name: "readHandFromBytes",
        outputs: [
          {
            name: "cards",
            type: "uint256[]"
          }
        ],
        payable: false,
        stateMutability: "pure",
        type: "function"
      },
      {
        constant: true,
        inputs: [
          {
            name: "_left",
            type: "uint256"
          },
          {
            name: "_right",
            type: "uint256"
          }
        ],
        name: "cardFight",
        outputs: [
          {
            name: "",
            type: "uint256"
          }
        ],
        payable: false,
        stateMutability: "pure",
        type: "function"
      },
      {
        constant: false,
        inputs: [
          {
            name: "_playerHand",
            type: "bytes32"
          }
        ],
        name: "roundResult",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function"
      },
      {
        constant: false,
        inputs: [
          {
            name: "SIGNER",
            type: "address"
          }
        ],
        name: "cancelBet",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function"
      }
    ];
  }
  updateAddress(address) {
    this.address = address;
  }

  updateProvider(provider) {
    //this.provider = provider
    //window.provider = provider;
  }

  async fetchData(endpoint) {
    return await fetch(endpoint).then(async response => {
      return await response.json().then(json => {
        return json.round;
      });
    });
  }
  async postfetchData(endpoint, payload) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const transport = {
      method: "POST",
      mode: "cors",
      cache: "default",
      headers,
      body: JSON.stringify(payload)
    };
    return await fetch(endpoint, transport).then(async response => {
      return await response.json().then(json => json);
    });
  }
  async fetchCards(bet = "0.001") {
    // mock response
    //return {}
    console.log(this.address);
    const res = await this.postfetchData(`${API}/cardGame`, {
      playerAddress: this.address,
      bet
    });
    console.log(res);
    return res.round;
  }

  /*
  // Outside of scope for this version
  async fetchSwappedCards(num){
    // mock response
    return this.fetchData(`${API}/cardGame?type=swap&num=${num}`);
  }*/

  async submitHand(playerHand) {
    const txToSign = await this.postfetchData(`${API}/cardGame/submitHand`, {
      hand: playerHand,
      playerAddress: this.address
    });

    /**/
    //const { utf8ToHex, padLeft } = web3.utils;
    // const playerCardBytes = padLeft(utf8ToHex(mappedPlayerHand), 64);

    const mappedHand = playerHand.reduce(
      (acc, card) => acc + getCardShortName(card),
      ""
    );

    const finalHand = utils.formatBytes32String(mappedHand);
    console.log({mappedHand, finalHand});

    const receipt = await this.signTransaction(txToSign, finalHand);
    console.log("Got receipt!");
    console.log(receipt);
    return receipt;
  }

  async signTransaction(txToSign, finalHand) {
    return await new Promise(async (resolve, reject) => {
      const { wallet, plasma} = this;
      const { address, roundCodeBuffer, txData, color } = txToSign;
      const { transactionIndex, value } = txData;

      const input = new Input({
        prevout: new Outpoint(address, transactionIndex),
        script: roundCodeBuffer
      });

      const msgSender = this.address;
      const output = new Output(value, msgSender, color);

      // create spending condition, sign it, send it and wait for receipt
      Tx.spendCond([input], [output])
        .signWeb3(wallet)
        .then(async signedTx => {
          console.log({ signedTx });


          const abi = new ethers.utils.Interface(this.roundLockABI);
          console.log("---- FINAL HAND ---", finalHand);
          const msgData = abi.functions["roundResult"].encode([finalHand]);

/*          const provider = new ethers.providers.JsonRpcProvider(
            "https://staging-testnet.leapdao.org/rpc"
          );
          const check = await provider.send("checkSpendingCondition", [
            signedTx.hex()
          ]);
          console.log(check);*/

          signedTx.inputs[0].setMsgData(msgData);
          return plasma.eth.sendSignedTransaction(signedTx.hex());
        })
        .then(receipt => {
          console.log("Receipt!");
          console.log(receipt);
          resolve(receipt);
        });
    });
  }

  async getEnemyDeck(receipt) {
    //TODO: fetch actual enemy deck
    //return ["Attack", "Attack","Attack","Attack","Attack"];
    const { hash } = receipt;
    console.log("Receipt hash:", hash);
    const result = await this.postfetchData(`${API}/cardGame/result`, {
      playerAddress: this.address,
      hash
    });
    const enemyDeck = result.houseHand;
    console.log(enemyDeck);
    return enemyDeck;
  }

  async pollServerForResponse(transaction) {
    let enemyHand = [];
    while (true) {
      const done = await new Promise(async (resolve, reject) => {
        // check every 3 seconds
        setTimeout(async () => {
          const res = await this.postfetchData(`${API}/cardGame/result`, {
            playerAddress: this.address,
            transaction
          });
          if (res.txStatus === "mined") {
            enemyHand = res.enemyHand;
            resolve(true);
          } else {
            resolve(false);
          }
        }, 3000);
      });

      if (done) {
        break;
      }
    }
    return enemyHand;
  }
}
