export default class Customizer{
	static applyConfig(hero, config){
		const {
			hairColor,
			eyeColor,
			hat,
			shield,
			weapon,
			chest,
			shoulder,
		} = config;

		this.changeHairColor(hero, hairColor);
		this.changeEyeColor(hero, eyeColor);
		this.changeHat(hero, hat);
		this.changeShield(hero, shield);
		this.changeChest(hero, chest);
		this.changeShoulder(hero, shoulder);
		this.changeWeapon(hero, weapon);
	}
	static changeHairColor(hero, newColor,config) {
		const noHatBasicShape = hero.skeleton.findSlot('hair-01-base-shape');
		const noHatShadow = hero.skeleton.findSlot('hair-01-shadow');
		const noHatHighlight = hero.skeleton.findSlot('hair-01-highlight');

		const commonBase = hero.skeleton.findSlot(
			'cloth-hat/hair-cloth-hat-base'
		);
		const commonShadow = hero.skeleton.findSlot(
			'cloth-hat/hair-cloth-hat-shadow'
		);

		const braid = hero.skeleton.findSlot('braid');

		const changeColor = colors => {
			const { base, shadow, highlight } = colors;
			noHatBasicShape.color = base;
			commonBase.color = base;
			braid.color = base;
			noHatShadow.color = shadow;
			commonShadow.color = shadow;
			noHatHighlight.color = highlight;
		};

		const a = 1;
		let r, g, b;
		switch (newColor) {
			case 'Red':
				r = 252 / 255;
				g = 75 / 255;
				b = 75 / 255;
				changeColor({
					base: { r, g, b, a },
					shadow: { r: r*0.85, g: g*0.85, b: b*0.85, a },
					highlight: { r: 1, g: 1, b: 1, a: 0.35 }
				});
				break;

			case 'Orange':
				r = 255 / 255;
				g = 142 / 255;
				b = 66 / 255;
				changeColor({
					base: { r, g, b, a },
					shadow: { r: r*0.85, g: g*0.85, b: b*0.85, a },
					highlight: { r: 1, g: 1, b: 1, a: 0.35 }
				});
				break;

			case 'Yellow':
				r = 253 / 255;
				g = 192 / 255;
				b = 14 / 255;
				changeColor({
					base: { r, g, b, a },
					shadow: { r: r*0.85, g: g*0.85, b: b*0.85, a },
					highlight: { r: 1, g: 1, b: 1, a: 0.35 }
				});
				break;

			case 'Green':
				r = 105 / 255;
				g = 229 / 255;
				b = 15 / 255;
				changeColor({
					base: { r, g, b, a },
					shadow: { r: r*0.85, g: g*0.85, b: b*0.85, a },
					highlight: { r: 1, g: 1, b: 1, a: 0.35 }
				});
				break;

			case 'Blue':
				r = 11 / 255;
				g = 215 / 255;
				b = 247 / 255;
				changeColor({
					base: { r, g, b, a },
					shadow: { r: r*0.85, g: g*0.85, b: b*0.85, a },
					highlight: { r: 1, g: 1, b: 1, a: 0.35 }
				});
				break;

			case 'Violet':
				r = 160 / 255;
				g = 100 / 255;
				b = 255 / 255;
				changeColor({
					base: { r, g, b, a },
					shadow: { r: r*0.85, g: g*0.85, b: b*0.85, a },
					highlight: { r: 1, g: 1, b: 1, a: 0.35 }
				});
				break;

			default:
				break;
		}

		if (config) {
			config.hairColor = newColor;
		}
	}
	static changeEyeColor(hero, newColor,config) {
		const pupil = hero.skeleton.findSlot('eyes/eye-happy-pupil');

		const a = 1;
		let r, g, b;
		switch (newColor) {
			case 'Red':
				r = 252 / 255;
				g = 75 / 255;
				b = 75 / 255;
				pupil.color = { r, g, b, a };
				break;

			case 'Orange':
				r = 255 / 255;
				g = 142 / 255;
				b = 66 / 255;
				pupil.color = { r, g, b, a };
				break;

			case 'Yellow':
				r = 253 / 255;
				g = 192 / 255;
				b = 14 / 255;
				pupil.color = { r, g, b, a };
				break;

			case 'Green':
				r = 105 / 255;
				g = 229 / 255;
				b = 15 / 255;
				pupil.color = { r, g, b, a };
				break;

			case 'Blue':
				r = 11 / 255;
				g = 215 / 255;
				b = 247 / 255;
				pupil.color = { r, g, b, a };
				break;

			case 'Violet':
				r = 160 / 255;
				g = 100 / 255;
				b = 255 / 255;
				pupil.color = { r, g, b, a };
				break;

			default:
				break;
		}
		if (config) {
			config.eyeColor = newColor;
		}
	}
	static changeHat(hero, newHat, config) {
		const _hideAttachment = (slot)=>{
			slot.setAttachment(null);
		};

		let epicHeadSlots = hero.skeleton.slots.filter(slot => {
			return slot.data.boneData.name === 'head-epic-hat';
		});

		let commonHeadSlots = hero.skeleton.slots.filter(slot => {
			return slot.data.boneData.name === 'head-cloth-hat';
		});

		let noHatSlots = hero.skeleton.slots.filter(slot => {
			return slot.data.boneData.name === 'head-no-hat';
		});

		switch (newHat) {
			case 'No Hat':
				epicHeadSlots.map(_hideAttachment);
				commonHeadSlots.map(_hideAttachment);

				noHatSlots.map(slot => {
					const slotName = slot.data.name;
					const name = slot.data.attachmentName;
					hero.skeleton.setAttachment(slotName, name);
					return slot;
				});

				break;
			case 'Common Hat':
				epicHeadSlots.map(_hideAttachment);
				noHatSlots.map(_hideAttachment);

				commonHeadSlots.map(slot => {
					const slotName = slot.data.name;
					const name = slot.data.attachmentName;
					hero.skeleton.setAttachment(slotName, name);
					return slot;
				});
				break;
			case 'Epic Helmet':
				commonHeadSlots.map(_hideAttachment);
				noHatSlots.map(_hideAttachment);

				epicHeadSlots.map(slot => {
					const slotName = slot.data.name;
					const name = slot.data.attachmentName;
					hero.skeleton.setAttachment(slotName, name);
					return slot;
				});
				break;
			default:
				break;
		}

		if (config) {
			config.hat = newHat
		}
	}
	static changeShield(hero, newShield, config) {
		switch (newShield) {
			case 'Common Shield':
				hero.skeleton.setAttachment('shield_01', 'shield_01');
				break;
			case 'Strong Shield':
				hero.skeleton.setAttachment('shield_01', 'shield_strong');
				break;
			case 'Epic Shield':
				hero.skeleton.setAttachment('shield_01', 'shield_epic');
				break;
			default:
				break;
		}
		if (config) {
			config.shield = newShield
		}
	}
	static changeWeapon(hero, newWeapon, config) {
		switch (newWeapon) {
			case 'Common Sword':
				hero.skeleton.setAttachment('weapon-slot', 'sword-common');
				break;
			case 'Rare Axe':
				hero.skeleton.setAttachment('weapon-slot', 'axe-common');
				break;
			case 'Epic Sword':
				hero.skeleton.setAttachment('weapon-slot', 'sword_epic');
				break;
			default:
				break;
		}

		// Update config on other side
		if (config) {
			config.weapon = newWeapon
		}
	}
	static changeShoulder(hero, newShoulder, config) {
		switch (newShoulder) {
			case 'No Shoulder':
				hero.skeleton.setAttachment('shoulder-pad', null);
				break;
			case 'Common Shoulder':
				hero.skeleton.setAttachment('shoulder-pad', 'shoulder-pad-strong');
				break;
			case 'Epic Shoulder':
				hero.skeleton.setAttachment('shoulder-pad', 'shoulder-pad-epic');
				break;
			default:
				break;
		}
		if (config) {
			config.shoulder = newShoulder;
		}
	}
	static changeChest(hero, newChest, config) {
		switch (newChest) {
			case 'No Chest Plate':
				hero.skeleton.setAttachment('chest_01', 'chest_01');
				break;
			case 'Epic Breastplate':
				hero.skeleton.setAttachment('chest_01', 'chest_epic');
				break;
			default:
				break;
		}
		config = newChest;
	}
}