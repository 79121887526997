/*import BG_LOGIN_FULLSCREEN from '../assets/login-background.jpg';
import MEPLY_LOGO from '../assets/meply-logo.png';
import MEPLY_LOGO_WHITE from '../assets/meply-logo-white.png';

import LINCOLN_LOGO from '../assets/logo-lincoln.jpg';

import SOCIAL_FB from '../assets/social-icons/icon-fb.jpg';
import SOCIAL_TWITTER from '../assets/social-icons/icon-twitter.jpg';
import SOCIAL_YOUTUBE from '../assets/social-icons/icon-youtube.jpg';

import ICON_CIRCLE_ADD from '../assets/add-circle.svg';
import ICON_DROP_DOWN from '../assets/arrow-drop-down.svg';
import ICON_CLOSE from '../assets/cancel.svg';*/

import DR_LOGO from '../images/dr-logo.png'; //
// import RUBY_RUNNER_LOGO from '../images/ruby-runner-logo.png';
// import POKER_FIGHTER_LOGO from '../images/poker-fighter-logo.png';

export default {
  DR_LOGO,
};
