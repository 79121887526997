import * as Howl from "howler";
import { Container, Graphics, Text } from "pixi.js";

export default class StartRoundButton extends Container {
  constructor(config) {
    super();
    // const type = 'Deal';
    //this.inactiveTexture = app.resources['btn'+type+'Process'].texture;
    //this.activeTexture = app.resources['btn'+type+'Start'].texture;
    //this.disabledTexture = app.resources['btn'+type+'NoBet'].texture;

    const { callback, activeLabel, inactiveLabel } = config;

    console.log(config);

    this.inactiveState = new Container();
    this.inactiveBG = new Graphics();
    this.inactiveBG.beginFill(0x555555, 0.6);
    this.inactiveBG.drawRoundedRect(0, 0, 300, 80, 15);
    this.inactiveBG.endFill();

    this.inactivelabelText = new Text(inactiveLabel, {
      fontFamily: "Arial",
      fontStyle: "bold",
      fontSize: "36px",
      fill: "rgba(255,255,255,1)"
    });

    this.inactiveState.addChild(this.inactiveBG);
    this.inactiveState.addChild(this.inactivelabelText);

    this.addChild(this.inactiveState);

    this.inactivelabelText.position = {
      x: this.inactiveBG.width / 2 - this.inactivelabelText.width / 2,
      y: this.inactiveBG.height / 2 - this.inactivelabelText.height / 2
    };

    //***********
    this.activeState = new Container();
    this.activeBG = new Graphics();
    this.activeBG.beginFill(0x00cc22, 1);
    this.activeBG.drawRoundedRect(0, 0, 300, 80, 15);
    this.activeBG.endFill();

    this.activelabelText = new Text(activeLabel, {
      fontFamily: "Arial",
      fontStyle: "bold",
      fontSize: "36px",
      fill: "rgba(255,255,255,1)"
    });

    this.activeState.addChild(this.activeBG);
    this.activeState.addChild(this.activelabelText);

    this.addChild(this.activeState);

    this.activelabelText.position = {
      x: this.activeBG.width / 2 - this.activelabelText.width / 2,
      y: this.activeBG.height / 2 - this.activelabelText.height / 2
    };

    this.inactiveState.renderable = false;

    //this.activeState = new Container();

    //this.btn = new Sprite(this.activeTexture);
    //this.btn.alpha = 1;

    this.interactive = true;
    this.clickCallback = callback;

/*    this.sound = new Howl({
      src: ["sounds/mouth-pop.wav"],
      volume: 0.25 * CONFIG.GLOBAL_VOLUME,
      rate: 1
    });*/
  }

  deactivate() {
    this.btn.alpha = 0.3;
    this.interactive = false;
    this.btn.texture = this.disabledTexture;
  }

  disable() {
    console.log("disable!!");
    this.inactiveState.renderable = true;
    this.activeState.renderable = false;
    this.interactive = false;
  }

  enable() {
    this.activeState.renderable = true;
    this.inactiveState.renderable = false;
    this.interactive = true;
  }

  tap() {
    this.clickCallback();
    this.sound.play();
  }

  mousedown() {
    this.clickCallback();
    this.sound.play();
  }
}
