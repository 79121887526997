import React from "react";
import GamePlayer from "../../GamePlayer";
import { LoadProgress } from "../../components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadComplete } from "../../reducers/game";
import { Redirect } from "react-router";

const Game = props => {
  const { assetsLoaded, loadComplete, balance } = props;
  if (balance === 0) {
    return <Redirect to={"/faucet"} />;
  }
  return (
    <>
      {assetsLoaded ? (
        <GamePlayer {...props} />
      ) : (
        <LoadProgress onLoadComplete={loadComplete} />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  balance: state.web3.balance,
  assetsLoaded: state.game.assetsLoaded,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadComplete
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Game);
