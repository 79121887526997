import { Container, Sprite, Text } from 'pixi.js';
import { TimelineMax } from 'gsap';

export default class Balance extends Container {
	constructor({ resources, value }, app) {
		super();
		let texture = resources['coin'].texture;
		this.app = app;
		this.coin = new Sprite.from(texture);
		this.coin.position = {
			x: 50,
			y: 50
		};
		this.addChild(this.coin);

		window.balance = this;

		this.value= value;

		this.valueText = new Text(this.value, {
			fontFamily: 'Ubuntu',
			fontSize: '32px',
			fontStyle: 'bold',
			fill: 'rgba(255,255,255,1)'
		});
		this.valueText.position = {
			x: this.coin.position.x + this.coin.width + 20,
			y: 60
		};
		this.addChild(this.valueText);
		this.updateValue(value);
		this.updateValue = this.updateValue.bind(this);

		this.timeline = new TimelineMax();
	}

	addCoins(amount) {
		this.value += amount;
		this.updateValue(this.value);
		//console.log(`Actual increase => Balance: ${this.value}`);
	}

	reduceCoins(amount){
		this.value -= amount;
		this.updateValue(this.value);
	}

	updateValue(newVal) {
	  console.log('New Value!', newVal);
		this.valueText.text = newVal;
	}

	suckCoin(coin, endCallback) {
		//console.log(`Add coin => Balance: ${this.value}`);
		const tl = new TimelineMax();
		tl
			.to(coin, 0.2, { x: 50, y: 50 })
			.to(this.coin.scale, 0.15, { x: 1.1, y: 1.1 }, 0.4)
			.to(this.coin.scale, 0.15, { x: 1, y: 1 }, 0.55)
			.add(()=> {
				// this.addCoins(1);
				endCallback();
				//this.app._soundController.play('coinDrop');
			}, 0.2);
	}
}
