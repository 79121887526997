import React, { Component } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { activateWallet } from "../../reducers/web3";
import { Header, Copy, CallToAction } from "../Faucet/style";

class ActivateMetamask extends Component {
  componentDidMount() {
    const { activateWallet } = this.props;
    activateWallet();
  }

  render() {
    const {
      walletInstalled,
      walletConnected,
      activateWallet,
      walletActivationProcess
    } = this.props;
    if (!walletInstalled || walletConnected) {
      return <Redirect to={"/"} />;
    }
    return (
      <>
        <Header>Metamask is not connected</Header>
        <Copy>Please, activate Metamask when you presented with prompt</Copy>
        {walletActivationProcess && (
          <CallToAction onClick={activateWallet}>Connect Metamask</CallToAction>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  walletInstalled: state.web3.walletInstalled,
  walletConnected: state.web3.walletConnected
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      activateWallet
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateMetamask);
