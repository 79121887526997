import * as PIXI from 'pixi.js';
import { Container, Sprite } from 'pixi.js';
import { spine } from 'pixi-spine';


export default class Level extends Container {
	constructor(app, window, map) {
		super();

		const { width, height } = window;
		const { tiles, frameContent, frameWidth, numFullFrames } = map;

		this._app = app;
		this._map = map;
		this._window = window;
		this._infiniteFrameWidth = (numFullFrames -1 )* frameWidth;

		this._tiledTextures = new Container();
		const assets = app.getAssets();
		for (let i = 0; i < tiles.length; i++) {
			const layer = tiles[i];
			let tiledLayer = new PIXI.extras.TilingSprite(
				assets[layer.texture].texture,
				width,
				layer.height
			);

			tiledLayer.position = {
				x: 0,
				y: layer.y
			};
			this._tiledTextures.addChild(tiledLayer);
		}
		this.addChild(this._tiledTextures);

		this._frames = new Container();
		this._animated = [];
		for (let i = 0; i < numFullFrames; i++) {
			const frame = new Container();
			frame.position.x = frameWidth * i;
			for (let j = 0; j < frameContent.length; j++) {
				const obj = frameContent[j];
				const newChild = this.objectFactory(obj);

				frame.addChild(newChild);
				newChild.position = obj.position;

				if (obj.type === 'torch') {
					newChild.state.setAnimation(1, 'animation', true);
					newChild.autoUpdate = false;
					newChild.skeleton.setToSetupPose();
					newChild.update(0);
					this._animated.push({
						target: newChild,
						renderArea: 200,
						parentFrame: frame
					});
				}
			}
			this._frames.addChild(frame);
		}

		const blurFilterBig = new PIXI.filters.BlurFilter();
		blurFilterBig.blur = 5;
		const chainTint = 0x333333;

		this._chains = new Container();

		this.firstChain = this.objectFactory({
			type: 'chain',
			scale: {x: 0.6, y: 0.75},
			anchor: {x: 0, y: 0},
			position: {x: 300, y: -70}
		});
		this.firstChain.state.timeScale = 0.25;
		this.firstChain.state.setAnimation(0, 'small-swing-arc', true);
		this.firstChain.filters = [blurFilterBig];
		this.firstChain.tint = chainTint;

/*		this._animated.push({
			target: this.firstChain,
			renderArea: 500,
			parentFrame: this.firstChain
		});*/

		this.secondChain = this.objectFactory({
			type: 'chain',
			scale: {x: 0.6, y: 0.75},
			anchor: {x: 0, y: 0},
			position: {x: 1300, y: -60}
		});
		this.secondChain.state.timeScale = 0.25;
		this.secondChain.state.setAnimation(0, 'small-swing-arc', true);
		this.secondChain.filters = [blurFilterBig];
		this.secondChain.tint = chainTint;

/*		this._animated.push({
			target: this.secondChain,
			renderArea: 500,
			parentFrame: this.secondChain,
		});*/

		this._chains.addChild(this.firstChain);
		this._chains.addChild(this.secondChain);


		this._mapStatic = new Container();


		this.addChild(this._frames);
		this.addChild(this._mapStatic);
		this.addChild(this._chains);
		this._mapPosition = 0;

	}

	objectFactory(schema) {
		const { type, scale, anchor, position } = schema;
		const assets = this._app.getAssets();
		let result = null;
		switch (type) {
			case 'column':
				result = new Sprite.from(assets['column'].texture);
				break;
			case 'torch':
				result = new PIXI.spine.Spine(assets['torch'].spineData);
				break;
			case 'sink':
				result = new Sprite.from(assets['sink'].texture);
				break;
			case 'chain':
				result = new PIXI.spine.Spine(assets['chain'].spineData);
			default:
				break;
		}
		result.scale = scale;
		result.anchor = anchor || { x: 0.5, y: 0.5 };
		result.position = position || {x: 0, y: 0};
		return result;
	}
	set mapPosition(newPos) {
		const oldPos = this._mapPosition;
		this._mapPosition = newPos;
		if (oldPos !== newPos) {
			this.updatePositions(newPos - oldPos);
		}
	}
	get mapPosition() {
		return this._mapPosition;
	}
	update(dt) {
		const len = this._frames.children.length;
		for (let j = 0; j < len; j++) {
			let obj = this._frames.children[j];
			const test = obj.position.x;
			if (test <= -250 || test >= this._window.width){
				obj.renderable = false;
			} else {
				obj.renderable = true;
			}
		}

		for (let i = 0; i < this._animated.length; i++) {
			const {parentFrame, renderArea, target, timeOffset} = this._animated[i];
			const test = parentFrame.position.x;
			const time = timeOffset ? dt + timeOffset : dt;
			if (test >= -renderArea && test <= this._window.width){
				target.update(dt);
			}
		}
	}
	updatePositions(shift) {
		const { _tiledTextures, _frames, _chains, _mapStatic, mapPosition, _window, _map} = this;
		const { width } = _window;
		const mapOffset = mapPosition > 1280
			? mapPosition % 1280
			: mapPosition;

		const tilesTextures = _tiledTextures.children;
		for (let i = 0; i < tilesTextures.length; i++) {
			tilesTextures[i].tilePosition.x = -mapPosition;
		}

		const staticObjects = _mapStatic.children;
		for (let i =0; i< staticObjects.length; i++){
			const obj = staticObjects[i];
			obj.position.x = obj.mapPosition.x - mapPosition;
		}

		const frames = _frames.children;
		const { frameWidth } = _map;

		for (let i = 0; i < frames.length; i++) {
			const frame = frames[i];
				frame.position.x -= shift;

				if (frame.position.x <= -frameWidth){
					frame.position.x = this._infiniteFrameWidth;
				}
		}

		const chains = _chains.children;
		const paralax = 1.3;
		for (let i = 0; i < chains.length; i++) {
			const chain = chains[i];
			chain.position.x -= shift * paralax;

			if (chain.position.x <= -500){
				chain.position.x = this._window.width + 350;
			}
		}
	}
}
