import styled from "styled-components";
import {WHITE, PRIMARY_COLOR} from "../../theme/colors"

export const Container = styled.div`
  color: ${WHITE};
  text-align: center;
`;
export const Progress = styled.div`
  font-size: 24px;
  margin: 0;
  font-weight: bold;
  margin-bottom: 0.25em;
  span {
    font-weight: bold;
  }
`;
export const FileProgress = styled.p`
  color: ${PRIMARY_COLOR};
  font-size: 16px;
  margin: 0;
`;
