import React, { Component } from "react";
import { connect } from "react-redux";
import { startBalancePolling, clearBalancePolling } from "../../reducers/web3";
import { FlexboxRow, FlexRowLeft, FlexRowRight } from "../Layout";
import {
  BigHeaderContainer,
  TopPart,
  LinkContainer,
  HeaderLink,
  Username,
  Balance,
  Logo,
  LevelContainer,
  LevelLabel,
  LevelValue,
  UserInfo
} from "./styles";
import Images from "../../theme/images";
import { bindActionCreators } from "redux";
import * as ethers from "ethers";

const { DR_LOGO } = Images;

class BigHeader extends Component {
  componentDidMount() {
    const { account } = this.props;
    if (account) {
      this.props.startBalancePolling(account);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { account } = this.props;
    if (account) {
      this.props.startBalancePolling(account);
    }
  }

  componentWillUnmount() {
    this.props.clearBalancePolling();
  }

  render() {
    const { balance } = this.props;
    const floatBalance =
      balance === "--" ? "--" : ethers.utils.formatEther(balance);
    const tokenName = "DUC";
    return (
      <BigHeaderContainer>
        <HeaderLink to={"/"} exact className={"logo"} title={"Home Page"}>
          <Logo src={DR_LOGO} alt={"Dungeon Riches"} />
        </HeaderLink>
        <TopPart>
          <FlexRowLeft>
            <LevelContainer>
              <LevelLabel>Lvl</LevelLabel>
              <LevelValue>{1}</LevelValue>
            </LevelContainer>
            <UserInfo>
              <Username>
                Welcome,<span>Hero</span>
              </Username>
              <Balance>
                Balance:
                <span title={"Dungeon Universal Coin"}>{`${floatBalance} ${tokenName}`}</span>
              </Balance>
            </UserInfo>
          </FlexRowLeft>
        </TopPart>
        <LinkContainer>
          <FlexboxRow justify={"flex-start"}>
            <HeaderLink to={"/"} exact>
              Play
            </HeaderLink>
            <HeaderLink to={"/faucet"} exact>
              Faucet
            </HeaderLink>
          </FlexboxRow>

          <FlexboxRow justify={"flex-end"}>
            <HeaderLink to={"/faq"}>FAQ</HeaderLink>
            <HeaderLink to={"/provably-fair"}>Provably Fair</HeaderLink>
            <HeaderLink to={"/contact-us"}>Contact Us</HeaderLink>
          </FlexboxRow>
        </LinkContainer>
      </BigHeaderContainer>
    );
  }
}

const mapStateToProps = state => ({
  balance: state.web3.balance,
  account: state.web3.account
});

const mapDispatchToProps = dipatch =>
  bindActionCreators(
    {
      startBalancePolling,
      clearBalancePolling
    },
    dipatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BigHeader);
