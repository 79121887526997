import { Container } from "pixi.js";
import { TimelineMax, Elastic } from "gsap";
import Card from "./Card";

export default class Game extends Container {
  constructor(app, api, parent) {
    super();
    this._app = app;
    this._parent = parent;
    this._resources = app.resources;
    this._cards = new Container();
    this._enemyCards = new Container();
    this._cardPadding = 15;
    this.addChild(this._cards);
    this.addChild(this._enemyCards);
    this._selectedCards = [];
    this._combatPlayer = app._combatPlayer;
    window._selectedCards = this._selectedCards;
    this.mode = "empty";
    this._api = api;
    console.log(app);
  }

  async initCards(callback) {
    this._cards.children = [];

    // Fetch Cards here
    const betMultiplier = this._parent.betController.betSize;
    console.log({ betMultiplier });
    let cards = await this._api.startRound(betMultiplier);
    if (typeof callback === "function") {
      callback();
    }

    let tl = new TimelineMax();
    for (let i = 0; i < cards.length; i++) {
      const card = new Card(
        {
          position: {
            x: 420 + (100 + this._cardPadding) * i,
            y: 400
          },
          card: {
            type: cards[i]
          },
          cardIndex: i
        },
        this._resources,
        this.onEvent.bind(this),
        this
      );
      this._cards.addChild(card);
      card.uniformScale = 0.75;
      tl.add(card.flipBackFront, i * 0.1);
    }
    tl.add(() => {
      this.mode = "second-deal"; // swap
    }, 0.5);
  }
  async swapCards() {
    let cardsToStay = [];
    let numSelectedCards = 0;
    for (let i = 0; i < this._cards.children.length; i++) {
      let card = this._cards.children[i];
      if (!card.selected) {
        //this._cards.removeChild(card);
        cardsToStay.push(card);
      } else {
        numSelectedCards += 1;
      }
    }
    this._cards.children = cardsToStay;

    let newCards = await this._api.fetchSwappedCards(numSelectedCards);

    let tl = new TimelineMax();

    for (let i = 0; i < newCards.length; i++) {
      const card = new Card(
        {
          position: {
            x: 1200,
            y: 400
          },
          card: {
            type: newCards[i]
          }
        },
        this._resources,
        this.onEvent.bind(this),
        this
      );
      card.uniformScale = 0.75;
      this._cards.addChild(card);
      tl.add(card.flipBackFront, i * 0.1 + 0.5);
    }

    for (let i = 0; i < this._cards.children.length; i++) {
      let card = this._cards.children[i];
      card.cardIndex = i;
      tl.to(
        card,
        1,
        {
          ease: Elastic.easeOut.config(1, 0.75),
          x: 420 + (100 + this._cardPadding) * i
        },
        i * 0.1
      );
    }
    tl.add(() => {
      this.mode = "second-deal";
    }, 0.5);
  }
  async playCards() {
    this.mode = "play";
    //this._app.enemyLifeBar._turnDamage = 0;
    let tl = new TimelineMax();

    //		tl.pause();

    const playerCards = this._cards.children;
    const lastAttackCard = playerCards.reduce((acc, item, i) => {
      let result = acc;

      //console.log(`Card #${i} - ${item.cardType}`);
      if (item.cardType === "Attack" || item.cardType === "Strong") {
        result = i;
      }
      return result;
    }, -1);

    //console.log(playerCards, lastAttackCard);

    for (let i = 0; i < playerCards.length; i++) {
      let card = playerCards[i];
      card.cardIndex = i;
      tl.to(
        card,
        1,
        {
          ease: Elastic.easeOut.config(1, 0.75),
          x: 300 + this._cardPadding * i,
          y: 550,
          uniformScale: 0.5
        },
        (playerCards.length - i) * 0.1
      );
    }

    // TODO: Hide buttons

    const { address } = this._app;
    const playerFinalHand = this._cards.children.map(card => card.cardType);

    // SUBMIT HAND
    const result = await this._api.submitHand(playerFinalHand);
    const enemyDeck = result.houseHand;
    //console.log('Receipt:', receipt);
    //const enemyDeck = await this._api.getEnemyDeck(receipt);
    for (let i = enemyDeck.length - 1; i >= 0; i--) {
      const card = new Card(
        {
          position: {
            x: 1200,
            y: 400
          },
          card: {
            type: enemyDeck[i]
          }
        },
        this._resources,
        this.onEvent.bind(this),
        this
      );
      card.uniformScale = 0.75;
      this._enemyCards.addChild(card);
      //flip card on deal
      tl
        //.add(() => card.flipBackFront(), i * 0.1)
        .to(
          card,
          1,
          {
            ease: Elastic.easeOut.config(1, 0.75),
            x: 950 + this._cardPadding * i,
            y: 550,
            uniformScale: 0.5
          },
          i * 0.1
        );
    }

    const setupTimeOffset = 0.75;
    const cardAnimOffset = 1.5;

    this._cards.children = this._cards.children.reverse();

    const round = {
      player: this._cards.children,
      enemy: this._enemyCards.children,
      lastAttackCard: lastAttackCard
    };
    this.playNextPair(round, round.player.length - 1, tl, setupTimeOffset);

    //		tl.play();
  }

  playNextPair(round, n, movie, timeOffset) {
    const { lastAttackCard } = round;
    console.log(`Last card is - ${lastAttackCard}`);
    const combatPlayer = this._combatPlayer;
    const enterTime = timeOffset;
    const pair = {
      player: round.player[n].cardType,
      enemy: round.enemy[n].cardType
    };

    console.log(pair);

    const playerCard = round.player[n];
    const enemyCard = round.enemy[n];

    let timeWaste = 0.75;

    if (pair.player === "Attack") {
      switch (pair.enemy) {
        case "Mana":
        case "Void": {
          const anim = combatPlayer.heroAttackSuccess(n, lastAttackCard);
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          break;
        }
        case "Attack":
        case "Strong": {
          const anim = combatPlayer.normalParry();
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          break;
        }
        case "Defense": {
          const anim = combatPlayer.heroAttackEnemyDefend();
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          break;
        }
        default:
          timeOffset += timeWaste;
          break;
      }
    }
    if (pair.player === "Mana") {
      switch (pair.enemy) {
        case "Void": {
          //movie.add(()=>this.heroAttackSuccess(n), timeOffset);
          timeOffset += timeWaste;
          break;
        }
        case "Defense": {
          //movie.add(()=>this.heroAttackEnemyDefend(), timeOffset);
          timeOffset += timeWaste;
          break;
        }
        case "Attack": {
          //  TODO: Fix this case
          //	player = '30012'.split('');
          //  enemy = '11144'.split('');
          console.log(timeOffset);
          const anim = combatPlayer.enemyAttackSuccess();
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          console.log(timeOffset, anim.timeShift);
          break;
        }
        case "Strong": {
          const anim = combatPlayer.enemyStrongAttackSuccess();
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          break;
        }
        default:
          timeOffset += timeWaste;
          break;
      }
    }
    if (pair.player === "Strong") {
      switch (pair.enemy) {
        case "Mana":
        case "Void": {
          const anim = combatPlayer.heroJumpAndCombo4(n, lastAttackCard);
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          break;
        }
        case "Attack":
        case "Strong": {
          const anim = combatPlayer.normalParry();
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          break;
        }
        case "Defense": {
          const anim = combatPlayer.heroJumpAttackDefense();
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          break;
        }
        default:
          timeOffset += timeWaste;
          break;
      }
    }
    if (pair.player === "Defense") {
      switch (pair.enemy) {
        case "Void": {
          const anim = combatPlayer.heroDefendEnemyLaugh();
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          break;
        }
        case "Attack": {
          const anim = combatPlayer.heroDefendEnemyAttack();
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          break;
        }
        case "Strong": {
          const anim = combatPlayer.heroDefendEnemyStrongAttack();
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          break;
        }
        default:
          timeOffset += timeWaste;
          break;
      }
    }

    if (pair.player === "Void") {
      switch (pair.enemy) {
        case "Void":
          //movie.add(() => this.enemyLaugh(), timeOffset);
          timeOffset += timeWaste;
          break;
        case "Defense":
          //movie.add(() => this.enemyLaugh(), timeOffset);
          timeOffset += timeWaste;
          break;
        case "Attack": {
          const anim = combatPlayer.enemyAttackSuccess();
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          break;
        }
        case "Strong": {
          const anim = combatPlayer.enemyStrongAttackSuccess();
          movie.add(anim.timeline, timeOffset);
          timeOffset += anim.timeShift;
          break;
        }
        default:
          timeOffset += timeWaste;
          break;
      }
    }

    this.cardPairPlayer(playerCard, enemyCard, movie, enterTime, timeOffset);

    if (n > 0) {
      this.playNextPair(round, n - 1, movie, timeOffset);
    } else {
      movie.add(() => {
        this._app.checkGameState();
      }, timeOffset);
    }
  }

  cardPairPlayer(first, second, timeline, timeOffset, exitTime) {
    timeline
      //.add(() => second.flipBackFront(), timeOffset)
      .to(first, 0.25, { x: 600, y: 500, uniformScale: 0.75 }, timeOffset)
      .to(first, 0.25, { x: 350, y: 200, alpha: 0 }, exitTime)

      .to(second, 0.25, { x: 730, y: 500, uniformScale: 0.75 }, timeOffset)
      .add(() => {
        second.flipBackFront();
      }, timeOffset)
      .to(second, 0.25, { x: 980, y: 200, alpha: 0 }, exitTime);
  }

  resetGame() {
    this.mode = "empty";
    this._selectedCards = [];
    this._cards.children = [];
    this._enemyCards.children = [];
  }

  clearSelection() {
    console.log("Clear All cards");
    for (let i = 0; i < this._cards.children.length; i++) {
      let card = this._cards.children[i];
      card.clearSelection();
    }
    this._selectedCards = [];
  }

  onEvent(event) {
    console.log(this.mode);
    if (this.mode === "second-deal" && event.type === "card-clicked") {
      const { cardIndex } = event.card;
      const i = this._selectedCards.indexOf(cardIndex);
      console.log(cardIndex);
      if (i >= 0) {
        this._selectedCards = this._selectedCards.filter(item => {
          console.log(item, cardIndex);
          return item !== cardIndex;
        });
      } else {
        this._selectedCards.push(cardIndex);
      }

      if (this._selectedCards.length === 2) {
        console.log(this._selectedCards);
        const c1 = this._selectedCards[0];
        const c2 = this._selectedCards[1];
        const first = this._cards.children[c1];
        const second = this._cards.children[c2];

        const t = first.cardIndex;
        first.cardIndex = second.cardIndex;
        second.cardIndex = t;
        this._cards.swapChildren(first, second);

        let tl = new TimelineMax();
        tl.to(
          first,
          0.5,
          {
            x: second.x
          },
          0
        )
          .to(
            second,
            0.5,
            {
              x: first.x
            },
            0
          )
          .add(() => {
            first.markForSwap();
            second.markForSwap();
            this._selectedCards = [];
          });
      }
    }
  }
}
