import Pixi, {Container, Sprite} from 'pixi.js'
import BetParam from './BetParam'

export default class BetController extends Container{
    constructor(app){
        super();

        this.app = app; //
        console.log('Initializing bet params');

        //this.initArray(params);
        this.offset = 0;
        this.betSize = 1;
    }


    initParam(param){
        let newParam = new BetParam(param, this, this.app);
        //console.log('New element width is: ' + newParam.width);
        newParam.position.x = this.offset;
        newParam.position.y = 40;
        this.offset += newParam.width + 40;
        this.addChild(newParam);
        //this.position.x = app.width/2 - this.width/2;

        this[param.type] = newParam;
        return this;
    }


}
