import { Container, Graphics, Text } from "pixi.js";
import CONFIG from "../const/config";
import { rgbToHex } from "../utils/Utils";

export default class BigButton extends Container {
  constructor(options, parent) {
    super();
    this._callback = options.callback;
    const activeColor = rgbToHex(options.activeColor);
    const inactiveColor = rgbToHex(options.inactiveColor);
    console.log(activeColor, inactiveColor);

    this.inactiveState = new Container();
    this.inactiveBG = new Graphics();
    this.inactiveBG.beginFill(inactiveColor, 0.6);
    this.inactiveBG.drawRoundedRect(0, 0, 200, 80, 15);
    this.inactiveBG.endFill();

    this.inactivelabelText = new Text(options.inactiveLabel.toUpperCase(), {
      fontFamily: "Arial",
      fontStyle: "bold",
      fontSize: "28px",
      fill: "rgba(255,255,255,1)"
    });

    this.inactiveState.addChild(this.inactiveBG);
    this.inactiveState.addChild(this.inactivelabelText);

    this.addChild(this.inactiveState);

    this.inactivelabelText.position = {
      x: this.inactiveBG.width / 2 - this.inactivelabelText.width / 2,
      y: this.inactiveBG.height / 2 - this.inactivelabelText.height / 2
    };

    this.activeState = new Container();
    this.activeBG = new Graphics();
    this.activeBG.beginFill(activeColor, 1);
    this.activeBG.drawRoundedRect(0, 0, 200, 80, 15);
    this.activeBG.endFill();

    this.activelabelText = new Text(options.activeLabel.toUpperCase(), {
      fontFamily: "Arial",
      fontStyle: "bold",
      fontSize: "28px",
      fill: "rgba(255,255,255,1)"
    });

    this.activeState.addChild(this.activeBG);
    this.activeState.addChild(this.activelabelText);

    this.addChild(this.activeState);

    this.activelabelText.position = {
      x: this.activeBG.width / 2 - this.activelabelText.width / 2,
      y: this.activeBG.height / 2 - this.activelabelText.height / 2
    };

    this.inactiveState.renderable = false;

    this.interactive = true;

/*    this.sound = new Howler({
      src: ["sounds/mouth-pop.wav"],
      volume: 0.25 * CONFIG.GLOBAL_VOLUME,
      rate: 1
    });*/
  }

  deactivate() {
    this.btn.alpha = 0.3;
    this.interactive = false;
    this.btn.texture = this.disabledTexture;
  }

  disable() {
    console.log("disable!!");
    this.inactiveState.renderable = true;
    this.activeState.renderable = false;
    this.interactive = false;
  }

  enable() {
    this.activeState.renderable = true;
    this.inactiveState.renderable = false;
    this.interactive = true;
  }

  tap() {
    this._callback();
    //this.sound.play();
  }

  mousedown() {
    this._callback();
    //this.sound.play();
  }
}
