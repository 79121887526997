import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateEmail,
  updateMaidenFlight,
  requestFaucet
} from "../../reducers/faucet";
import { Header, Copy, CallToAction } from "./style";

class Faucet extends Component {
  renderBalanceIsNotZero = () => {
    return (
      <>
        <Header>Stay strong, Hero!</Header>
        <Copy>
          We see you still have some "ducks" to keep you afloat. Good luck!
        </Copy>
      </>
    );
  };

  renderEmailForm = () => {
    return (
      <>
        <Header>Please, provide your email</Header>
        <Copy>
          If you want to help us improve the game and let us notice you when
          it's ready, please provide us your email
        </Copy>
      </>
    );
  };

  renderGetFaucet = () => {
    const { requestFaucet, account, tokenColor } = this.props;
    const { faucetProgress, faucetStatus } = this.props;
    console.log(account, tokenColor);
    if (faucetStatus === 'claimed'){
      return (
        <>
          <Header>You've already claimed faucet</Header>
          <Copy>You can do it only ONCE per 24h period. Come back later</Copy>
        </>
      )
    }
    if (faucetStatus === "success"){
      return (
        <>
          <Header>Birds are on the way!</Header>
          <Copy>We've processed your request and you should hear ducs pretty soon...</Copy>
        </>
      )
    }
    return (
      <>
        <Header>Enjoy Free Faucet</Header>
        {!faucetProgress && (
          <>
            <CallToAction onClick={() => requestFaucet(account, tokenColor)}>
              Show me the money!
            </CallToAction>
          </>
        )}
        {faucetProgress && <Copy>Processing your request. Please, wait...</Copy>}
      </>
    );
  };

  render() {
    // const { emailProvided } = this.props;
    const { balance } = this.props;
    const balanceIsZero = true //balance == 0;
    return balanceIsZero
      ? this.renderGetFaucet()
      : this.renderBalanceIsNotZero();
  }
}

const mapStateToProps = state => ({
  balance: state.web3.balance,
  account: state.web3.account,
  tokenColor: state.web3.tokenColor,
  faucetProgress: state.faucet.faucetProgress,
  faucetStatus: state.faucet.faucetStatus,
  emailProvided: state.faucet.emailProvided,
  maidenFlight: state.faucet.maidenFlight
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateEmail,
      updateMaidenFlight,
      requestFaucet
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Faucet);
