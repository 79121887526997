import { utils } from "ethers";
import { ROUND_SERVER as API } from "../../../config";
import { getCardShortName } from "../../../utils/cards";
import { postfetchData } from "../../../utils/transport";
import { showLog } from "../../../utils/helpers";
import { Tx, helpers } from "leap-core";

const GAME_API = API + "/cardGame";

export default class LeapGameAPI {
  constructor(props) {
    const { account, plasma, wallet } = props;
    this.address = account;
    this.plasma = plasma;
    this.wallet = wallet;
    console.log("Leap Game API is instantiated!");
    this.fetchCards = this.fetchCards.bind(this);
    this.submitHand = this.submitHand.bind(this);
  }

  updateAddress(address) {
    this.address = address;
  }

  async startRound(betMultiplier = 1) {
    const playerAddress = this.address;
    const res = await postfetchData(`${GAME_API}/startRound`, {
      playerAddress,
      betMultiplier
    });
    console.log(res);

    const { roundAddress, tokenColor } = res;
    const playerBetHash = await this.sendBet(roundAddress, betMultiplier); // get actual hash by making a transfer
    const amount = utils.parseEther("0.00001") * 1;
    const { plasma, wallet } = this;

    const color = parseInt(tokenColor) //0x2; // TODO: replace real token color

    const betTx = await new Promise((resolve, reject) => {
      plasma
        .getUnspent(this.address)
        .then(unspent => {
          const inputs = helpers.calcInputs(
            unspent,
            this.address,
            amount,
            color
          );
          const outputs = helpers.calcOutputs(
            unspent,
            inputs,
            this.address,
            roundAddress,
            amount,
            color
          );
          return Tx.transfer(inputs, outputs);
        })
        .then(tx => tx.signWeb3(wallet))
        .then(
          signedTx => {
            return plasma.eth.sendSignedTransaction(signedTx.hex());
          },
          err => {
            reject(err);
          }
        )
        .then(receipt => {
          showLog(receipt, "Bet Transaction Receipt");
          resolve(receipt);
        });
    });

    //const signed = await betTx.signWeb3(wallet);
    //const betTxHash = plasma.eth.sendSignedTransaction(signed.hex());
    showLog(betTx, "Bet Hash");
    const { hash } = betTx;
    const { playerHand } = await this.fetchCards(hash);
    return playerHand;
    //return this.fetchCards(playerBetHash);
  }

  async fetchCards(betHash) {
    const playerAddress = this.address;
    return await postfetchData(`${GAME_API}/getHand`, {
      playerAddress,
      betHash
    });
  }

  async placeBet(bet) {}

  async submitHand(finalHand) {
    console.log('GOT HAND:', finalHand);
    const playerAddress = this.address;
    const mappedHand = finalHand.reduce(
      (acc, card) => acc + getCardShortName(card),
      ""
    );
    const playerHand = utils.formatBytes32String(mappedHand);
    console.log({ mappedHand, playerHand });
    return  await postfetchData(`${GAME_API}/submitHand`, {
      playerAddress,
      playerHand
    });
  }

  async sendBet(roundAddress, betMultiplier) {}
}
