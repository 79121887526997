export const PRIMARY_COLOR = '#b087bc';
export const SECONDARY_COLOR = '#7f6191';
export const THIRD_COLOR = '#3d2c40';
export const FOURTH_COLOR = '#291e2f';

export const MAIN_BG = FOURTH_COLOR;

export const YELLOW = '#F2C94C';
export const ORANGE = '#F2994A';
export const RED = '#EB5757';
export const GREEN = '#27AE60';
export const CYAN = '#56CCF2';

export const LIGHT_CYAN = '#e2f5f4';
export const WHITE = '#FFF';
export const LIGHTEST_GREY = '#f8f8f8';
export const LIGHT_GREY = '#e2e2e2';
export const DARK_GREY = '#9b9b9b';
export const DARKEST_GREY = '#4a4a4a';


export const HEADER_BG_COLOR = FOURTH_COLOR;
export const HEADER_TOP_BG_COLOR ='#4C3B63';
export const HEADER_BOTTOM_BG_COLOR ='#382A4B';

export const HEADER_LINK_ACTIVE = '#D1C5E0';
export const HEADER_LINK_NORMAL = '#8968B6';

export const USERNAME_COLOR = '#A597B9';
export const BALANCE_COLOR = '#FFFFFF';
export const LEVEL_CONTAINER_COLOR = '#382A4B';

export const ICON_COLOR_ACTVE = '#FFFFFF';
export const ICON_COLOR_NORMAL = '#D1C5E0';

export const TEXT_HEADER_COLOR = '#FFFFFF';
export const TEXT_COPY_COLOR = '#BDBDBD';

export const CODE_BLOCK_BG = '#35283c';
export const CODE_BLOCK_COLOR = '#CDABFB';

export const FOOTER_BG_COLOR = '#382A4B';
export const FOOTER_COPYRIGHT_COLOR = '#574570';
export const FOOTER_LINK_TEXT = '#A597B9';
export const FOOTER_LINK_DIVIDER = '#604981';
export const FOOTER_SOCIAL_LINK = '#574570';

export const TAB_ITEM_ACTIVE = ORANGE;
export const TAB_ITEM_NORMAL ='#857799';

export const INPUT_VALUE_COLOR = WHITE;
export const INPUT_LABEL_COLOR = WHITE;
export const INPUT_BORDER_COLOR = '#4C3B63';
export const INPUT_HELPER_COLOR = '#675480';

export const AUTH_LINK = CYAN;
export const ERROR_COLOR = RED;
export const OK_COLOR = GREEN;
export const WARNING_COLOR = YELLOW;

export const SWITCHER_BG_OFF = '#4C3B63';
