export const sounds = [
	{
		name: 'normalHit',
		src: ['sounds/slash.wav'],
		volume: 1,
		rate: 1
	},
	{
		name: 'shieldHit',
		src: ['sounds/shield-hit.wav'],
		volume: 1,
		rate: 1
	},
	{
		name: 'cardPlaced',
		src: ['sounds/cardPlace.wav'],
		volume: 1
	},

	{
		name: 'coinPickup',
		src: ['sounds/coin-drop.mp3'],
		volume: 1
	},

	{
		name: 'laugh',
		src: ['sounds/evil-laugh.mp3'],
		sprite: {
			triple: [0, 1200]
		}
	},
	{
		name: 'coinDrop',
		src: ['sounds/coin-drop.mp3'],
		//src: ['sounds/coin.wav'],
		volume: 1
	},
	{
		name: 'swordClash',
		src: ['sounds/swords-clash.mp3'],
		volume: 1,
		rate: 1
	},
	{
		name: 'btnPop',
		src: ['sounds/mouth-pop.wav'],
		volume: 0.25,
		rate: 1
	},
	{
		name: 'bgMusic',
		src: ['sounds/ost.mp3'],
		volume: 1,
		loop: true,
		rate: 1
	},
];
