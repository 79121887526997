import Pixi, {Container, Graphics} from 'pixi.js'

export default class ProgressBar extends Container{
    constructor(initLevel, maxCharge, color, app){
        super();
        this.maxCharge = maxCharge;
        this.app = app;
        this.color = color;
        this.currentLevel = initLevel;

        // console.log('Progres color '  + color);

        const
            MAX_WIDTH  = 102,
            RECT_PADDING = 5,
            //RECT_WIDTH = (MAX_WIDTH / maxCharge) - RECT_PADDING,
            RECT_WIDTH = 10,
            RECT_HEIGHT = 15;

        for (let i = 0; i < maxCharge; i++){
            let bar = new Graphics();
            bar.beginFill(color);
            bar.drawRect(0, 0, RECT_WIDTH, RECT_HEIGHT);
            bar.position.x = i*(RECT_PADDING + RECT_WIDTH);
            bar.position.y = 0;
            bar.endFill();
            bar.alpha = 0.1;
            if (i < initLevel){
                bar.alpha = 1; //
            }
            this.addChild(bar);
        }

    }

    showLevel(level){
        for(let i = 0; i < this.maxCharge; i++){
            if (i < level){
                this.children[i].alpha = 1;
            }
            else{
                this.children[i].alpha = 0.1;
            }
        }
    }
}
