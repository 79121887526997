import React from "react";
import { connect } from "react-redux";
import { loader, ticker, WebGLRenderer, Container } from "pixi.js";
import { playerConfig } from "./const";
import { FightScene } from "./scenes";
import BigButton from "./ui/StartRoundButton";
import SoundController from "./components/SoundController";
import { sounds } from "./components/SoundController/resources";
import { GameContainer } from "./styles";

import { Leap, LeapGame } from "./api";
import {
  heroAssets,
  bgAssets,
  inventoryAssets
} from "./components/Loader/resources";
import { ethers } from "ethers";

class GamePlayer extends React.Component {
  constructor(props) {
    super(props);
    this.root = React.createRef();
    // bind methods to this instance
    this._getDimensions = this._getDimensions.bind(this);

    // or we can get this from props
    const resolution = window.devicePixelRatio;
    this._playerWidth = 1280;
    this._playerHeight = 720;
    const antialias = true;

    this.api = new LeapGame(props);
    window.api = this.api;

    // Init renderer
    this._renderer = new WebGLRenderer(this._playerWidth, this._playerHeight, {
      antialias,
      resolution
    });

    // Init timer
    this._DELTA_TIME = 0;
    this._LAST_TIME = Date.now();

    // Load assets
    this._initSounds();
    this._activeStage = new Container();
    this._assets = this.props.resources;
    this._addTicker();
    this._showStartScreen();
    this.updateBalance(this.props.balance);
  }

  updateBalance = newBalance => {
    const formattedBalance =
      newBalance === "--" ? "--" : ethers.utils.formatEther(newBalance);
    if (this._fightScene && this._fightScene.balanceController) {
      this._fightScene.balanceController.updateValue(formattedBalance);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { balance } = nextProps;
    /*    if (balance !== this.props.balance) {
      // send server update about transaction here
      const bigBalance = balance;
      this.updateBalance(bigBalance);
    }*/
    const bigBalance = balance;
    this.updateBalance(bigBalance);
  }

  _initSounds() {
    this._soundController = new SoundController(sounds);
  }
  _renderFrame() {
    this._DELTA_TIME = Date.now() - this._LAST_TIME;
    this._LAST_TIME = Date.now();
    let dt = this._DELTA_TIME / 1000;
    this._activeStage.update(dt);

    this._renderer.render(this._activeStage);
  }
  _addTicker() {
    this._ticker = ticker.shared;
    this._ticker.autoStart = true;
    this._ticker.add(this._renderFrame, this);
  }
  _getDimensions() {
    return {
      width: this._playerWidth,
      height: this._playerHeight
    };
  }

  addUI() {
    /*    this.btnInventory = new BigButton(
      {
        activeColor: "rgb(0,200,65)",
        inactiveColor: "rgb(10,90,35)",
        activeLabel: "Inventory",
        inactiveLabel: "Wait...",
        callback: () => {} //this.showInventory.bind(this)
      },
      this._fightScene
    );
    this._fightScene.addChild(this.btnInventory);

    this.btnInventory.position = {
      x: 960,
      y: 20
    };*/

    this.btnGameplay = new BigButton({
      activeColor: "rgb(0,200,65)",
      inactiveColor: "rgb(10,90,35)",
      activeLabel: "Back",
      inactiveLabel: "Wait...",
      callback: () => {} //this.showGameplay.bind(this)
    });
    //this._inventory.addChild(this.btnGameplay);

    this.btnGameplay.position = {
      x: 960,
      y: 20
    };
  }

  _showStartScreen() {
    const { resources } = this.props;
    this._fightScene = new FightScene(this, playerConfig, resources);
    this._activeStage = this._fightScene;
  }

  getSoundController() {
    return this._soundController;
  }

  getAssets() {
    return this._assets;
  }

  shouldComponentUpdate() {
    //return false;
    return false;
  }

  componentDidMount() {
    this.root.current.appendChild(this._renderer.view);
  }
  render() {
    return <GameContainer ref={this.root} />;
  }
}

const mapStateToProps = state => ({
  account: state.web3.account,
  tokenColor: state.web3.tokenColor,
  balance: state.web3.balance,
  assetsLoaded: state.game.assetsLoaded,
  resources: state.game.resources,
  plasma: state.web3.plasma,
  rpc: state.web3.rpc,
  wallet: state.web3.wallet,
});

export default connect(mapStateToProps)(GamePlayer);
