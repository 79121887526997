import { Howl } from 'howler';

export default class SoundController {
	constructor(soundList) {
		this._sounds = {};
		const lenght = soundList.length;
		for (let i = 0; i < lenght; i++) {
			const { name, src, volume, rate, loop} = soundList[i];
			this._sounds[name] = new Howl({
				src, volume, rate, loop
			});
		}
	}

	play(sound, volume = 1) {
		this._sounds[sound].volume = volume;
		//this._sounds[sound].play();
	}
}
