import InfiniteLevel from '../level/InfiniteLevel';
import {Container} from 'pixi.js';

export const mockMap = {
	tiles: [
		{
			texture: 'wall-tile',
			height: 420,
			y: 0
		},
		{
			texture: 'ground-tile',
			height: 300,
			y: 420
		}
	],
	frameContent: [
		{
			type: 'column',
			position: {
				x: 0,
				y: 460
			},
			scale:{
				x: 0.6,
				y: 0.6
			},
			anchor: {
				x: 0,
				y: 1
			}
		},
		{
			type: 'torch',
			position: {
				x: 130,
				y: 250
			},
			scale:{
				x: 0.6,
				y: 0.6
			},
			anchor: {
				x: 0.5,
				y: 0
			}
		}
	],
	frameWidth: 800,
	numFullFrames: 5
};

export default class Dungeon extends Container{
	constructor(app){
		super();
		this._level = new InfiniteLevel(app, {
			width: app.width,
			height: app.height
		}, mockMap);

		this.addChild(this._level);
		window.level = this._level;
	}

	update(dt){
		this._level.update(dt);
	}
}