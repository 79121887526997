import {utils} from 'pixi.js'

export const shuffle = array => {
	var currentIndex = array.length,
		temporaryValue,
		randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
};

window.utils = utils;

function componentsToHex(R,G,B) {
	return toHex(R)+toHex(G)+toHex(B)
};

function toHex(n) {
	n = parseInt(n,10);
	if (isNaN(n)) return "00";
	n = Math.max(0,Math.min(n,255));
	return "0123456789ABCDEF".charAt((n-n%16)/16)
		+ "0123456789ABCDEF".charAt(n%16);
}
export const rgbToHex = (rgbString) =>{
	const pattern = /\((.*?)\)/;
	const array = pattern.exec(rgbString)[1].split(',');
	return `0x${componentsToHex.apply(null, array)}`;
};
