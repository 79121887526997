export const mockDungeonEnemies = [
	{
		name: 'Mr. Bones',
		hp: 120,
	},
	{
		name: 'Manny Calavera',
		hp: 250,
	},
	{
		name: 'Sir Daniel',
		hp: 500,
	}
];