import styled from "styled-components";
import { FOURTH_COLOR } from "../../theme/colors";
import { FlexboxColumn } from "../../components/Layout";

export const AppContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: ${FOURTH_COLOR};
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
`;

export const ContentContainer = styled(FlexboxColumn)`
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
`;
