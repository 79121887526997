import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import web3 from "./web3";
import game from "./game";
import faucet from "./faucet";

export default history =>
  combineReducers({
    web3,
    game,
    faucet,
    router: connectRouter(history)
  });
