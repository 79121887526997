import styled from "styled-components";

export const GameContainer = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  canvas{
    width: 100%;
  }
`;
