import { Container, Text } from 'pixi.js';
import BetController from '../BetController';
import BigButton from '../BigButton';
import Game from './game';

export default class CardGameUI extends Container {
	constructor(app, api, dimensions, eventBus) {
		const {width, height} = dimensions;
		super();
		this.app = app;
		this._eventBus = eventBus;

		this.btnStartRound = new BigButton(
			{
				activeColor: 'rgb(0,200,65)',
				inactiveColor: 'rgb(10,90,35)',
				activeLabel: 'Fight!',
				inactiveLabel: 'Wait...',
				callback: this.startRound.bind(this)
			},
			app
		);
/*
		this.btnSwap = new BigButton(
			{
				activeColor: 'rgb(230,180,15)',
				inactiveColor: 'rgb(90,75,5)',
				activeLabel: 'Swap',
				inactiveLabel: 'Swap',
				callback: this.swapCards.bind(this)
			},
			app
		);
*/

		this.placeBet = new Text('Pick your Bet', {
			fontFamily: 'Ubuntu',
			fontSize: '26px',
			fontStyle: 'bold',
			fill: 'rgba(255,255,0,1)'
		});

		this.placeBet.position ={
			x: 600,
			y: 500
		};
		this.addChild(this.placeBet);

		this.swapCards = new Text('You can pick cards you want to replace', {
			fontFamily: 'Ubuntu',
			fontSize: '26px',
			fontStyle: 'bold',
			fill: 'rgba(255,255,0,1)'
		});
		this.swapCards.position ={
			x: 420,
			y: 620
		};
		this.addChild(this.swapCards);

		this.reorderCards = new Text('You can reorder cards before fight', {
			fontFamily: 'Ubuntu',
			fontSize: '26px',
			fontStyle: 'bold',
			fill: 'rgba(255,255,0,1)'
		});

		this.reorderCards.position ={
			x: 420,
			y: 620
		};
		this.addChild(this.reorderCards);

		this.placeBet.renderable = true;
		this.swapCards.renderable = false;
		this.reorderCards.renderable = false;

		//this.btnSwap.alpha = 0;
		//this.btnSwap.interactive = false;

/*		this.btnReset = new BigButton(
			{
				activeColor: 'rgb(230,180,15)',
				inactiveColor: 'rgb(90,75,5)',
				activeLabel: 'Reset',
				inactiveLabel: 'Reset',
				callback: this.resetGame.bind(this)
			},
			app
		);*/
		/*		this.btnReset.position = {
					x: 20,
					y: 20
				};*/

		this.middleContainer = new Container();
		this.middleContainer.width = 600;

		this.betController = new BetController(app);
		this.betController
			.initParam({
				label: 'Coins per Card',
				initLevel: 1,
				maxCharge: 12,
				type: 'crystalAmount',
				color: '0x00CCCC'
			})
			.initParam({
				label: 'Multiplier',
				initLevel: 1,
				maxCharge: 5,
				type: 'crystalSize',
				color: '0xb260ef'
			});

		this.betController.on('state-changed', state => {
			if (!this.spinning) {
				let cA, cM, betSize;

				switch (state.type) {
					case 'crystalSize':
						this.betController.crystalAmount.setMultiplier(state.level);
						cA = this.betController.crystalAmount.currentLevel;
						cM = this.betController.crystalAmount.multiplier;
						betSize = cA * cM;
						break;
					case 'crystalAmount':
						cA = this.betController.crystalAmount.currentLevel;
						cM = this.betController.crystalAmount.multiplier;
						betSize = cA * cM;
						break;
					default:
						break;
				}

				this.betController.betSize = betSize;

			} else {
			}
		});

		this._cardGame = new Game(app, api, this);

		this.middleContainer.addChild(this.betController);
		this.middleContainer.addChild(this._cardGame);


		//this.addChild(this.btnReset);
		//this.addChild(this.btnSwap);
		this.addChild(this.middleContainer);
		this.addChild(this.betController);
		this.addChild(this.btnStartRound);

		const sidePadding = 120;
		const bottomPadding = 40;



		/*this.btnSwap.position = {
			x: sidePadding,
			y: height - this.btnSwap.height - bottomPadding
		};*/

		this.btnStartRound.position = {
			x: width - this.btnStartRound.width - sidePadding,
			y: height - this.btnStartRound.height - bottomPadding
		};

		this.betController.position = {
			x: width/2 - this.betController.width/2,
			y: height - this.betController.height*1.5 - bottomPadding
		}

		/*		const { children } = this;

		const { length } = children;
		const padding = 40;
		for (let i = 0; i < length; i++) {
			const child = children[i];
			const yOffset = child.height/2;
			console.log(child.width);
			if (i > 0) {
				const nX = children[i - 1].width + children[i - 1].position.x + padding;
				//console.log(alignElement.height, child.height, `Offset => ${(alignElement.height - child.height)/2}`);
				child.position.x = nX;
			} else {
				child.position.x = 0;
			}

			child.position.y = 0;
		}

		this.position.x = width / 2 - this.width / 2;
		this.position.y = height - this.height - 40;*/
	}

	resetGame(){
		this._cardGame.resetGame();
		this.betController.alpha = 1;
		this.betController.interactive = true;

		this.btnStartRound.alpha = 1;
		this.btnStartRound.interactive = true;

		this.placeBet.renderable = true;
		this.swapCards.renderable = false;
		this.reorderCards.renderable = false;

	}

	startRound(){
	  switch (this._cardGame.mode) {
      case "empty":{
        this.betController.alpha = 0;
        this.betController.interactive = false;

        //this.btnSwap.alpha = 1;
        //this.btnSwap.interactive = true;

        this.placeBet.renderable = false;
        this.swapCards.renderable = false;
        this.reorderCards.renderable = false;

        this._eventBus({
          type: 'place-bet',
          value: this.betController.betSize * 5
        });

        this.btnStartRound.alpha = 0;
        this.btnStartRound.interactive = false;
        this._cardGame.initCards(()=>{
          this.btnStartRound.alpha = 1;
          this.btnStartRound.interactive = true;
        });
        break;
      }
      case "first-deal":{
        this._cardGame.clearSelection();
        this._cardGame.mode = 'second-deal';
        //this.btnSwap.alpha = 0;
        //this.btnSwap.interactive = false;

        this.placeBet.renderable = false;
        this.swapCards.renderable = false;
        this.reorderCards.renderable = true;
        break;
      }
      default:
        this._cardGame.playCards();

        this.btnStartRound.alpha = 0;
        this.btnStartRound.interactive = false;
        //this.btnSwap.alpha = 0;
        //this.btnSwap.interactive = false;

        this._cardGame.clearSelection();
        this.placeBet.renderable = false;
        this.swapCards.renderable = false;
        this.reorderCards.renderable = false;
        break;
    }
	}

	swapCards(){
		this._cardGame.swapCards();
		//this.btnSwap.alpha = 0;
		//this.btnSwap.interactive = false;

		this.placeBet.renderable = false;
		this.swapCards.renderable = false;
		this.reorderCards.renderable = true;
	}
}
