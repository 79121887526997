import styled from 'styled-components';
import {
	DARK_GREY, FOOTER_BG_COLOR, FOOTER_COPYRIGHT_COLOR, FOOTER_LINK_DIVIDER, FOOTER_LINK_TEXT, FOOTER_SOCIAL_LINK,
	FOURTH_COLOR, LIGHT_GREY,
	LIGHTEST_GREY,
	SECONDARY_COLOR,
	THIRD_COLOR
} from "../../theme/colors";
import {FlexboxColumn, FlexboxRow} from "../Layout";
import { NavLink } from 'react-router-dom';

export const FooterContainer = styled(FlexboxColumn)`
	font-size: 12px;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 20px;
	background-color: ${FOOTER_BG_COLOR};
`;

export const MainBlock = styled(FlexboxRow)`
	color: ${LIGHTEST_GREY};
	background-color: ${THIRD_COLOR};
	padding: 10px;
	box-sizing: border-box;
`;

export const Copyright = styled.p`
	color: ${FOOTER_COPYRIGHT_COLOR};
	font-size: 11px;
	padding: 10px;
	box-sizing: border-box;
	text-align: left;
	margin: 0;
`;

export const SocialContainer = styled(FlexboxRow)`
	width: auto;
	& a{
		text-decoration: none;
	}
`;

export const FooterLink = styled(NavLink)`
	box-shadow: 1px 0 ${FOOTER_LINK_DIVIDER};
	padding: 5px 16px;
	margin-right: 5px;
	color: ${FOOTER_LINK_TEXT};
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 600;
	text-decoration: none;
`;

export const SocialLink = styled(NavLink)`
	background-color: ${FOOTER_SOCIAL_LINK};
	padding: 10px;
	font-size: 20px;
	color: white;
	box-sizing: border-box;
	border-radius: 20px;
	width: 40px;
	height: 40px;
	display: flex;
	text-decoration: none;
	justify-content: center;
	align-items: center;
	margin-right: 8px;
	&:last-child{
		margin-right: 0;
	}
`;
