export const getCard = num => {
  switch (num) {
    case 0:
      return "Void";
    case 1:
      return "Attack";
    case 2:
      return "Defense";
    case 3:
      return "Mana";
    case 4:
      return "Strong";
    default:
      return "Void";
  }
};
export const getCardShortName = card => {
  switch (card) {
    case "Attack": {
      return "A1";
    }
    case "Strong": {
      return "A2";
    }
    case "Defense": {
      return "D1";
    }
    case "Mana": {
      return "M1";
    }
    case "Void": {
      return "V0";
    }
    default: {
      return "V0"
    }
  }
};
export const numToBase5 = num => {
  return parseInt(num) % 5;
};
