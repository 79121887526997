import React, { Component } from "react";
import AssetLoader from "../../GamePlayer/components/Loader";
import {
  bgAssets,
  cardAssets,
  effectsAssets,
  enemyAssets,
  heroAssets,
  uiAssets
} from "../../GamePlayer/components/Loader/resources";

import { Container, Progress, FileProgress } from "./styles";

class LoadProgress extends Component {
  state = {
    progress: 0,
    description: "--"
  };
  componentDidMount() {
    const { onLoadComplete } = this.props;
    this._loader = new AssetLoader(
      [
        ...bgAssets,
        ...uiAssets,
        ...heroAssets,
        ...effectsAssets,
        ...enemyAssets,
        ...cardAssets
      ],
      (_, resources) => {
        onLoadComplete(resources);
      },
      loadedFile => {
        const { progress, description } = loadedFile;
        this.setState({
          progress: progress,
          description
        });
      }
    );
  }

  render() {
    const { progress, description } = this.state;
    const percentage = `${Math.floor(progress)}%`;
    return (
      <Container>
        <Progress>
          Progress: <span>{percentage}</span>
        </Progress>
        <FileProgress>{`${description} loaded`}</FileProgress>
      </Container>
    );
  }
}

export default LoadProgress;
