import { Container, Sprite } from 'pixi.js';

export default class BetButton extends Container {
	constructor(type, action, app) {
		super();
		const assets = app.getAssets();
		this._soundController = app.getSoundController();
		this.inactiveTexture =
			assets['btn' + type + 'Inactive'].texture;
		this.btn = new Sprite(this.inactiveTexture);
		this.interactive = true;
		this.action = action;

		this.addChild(this.btn);
/*		this.mousedown = this.mousedown.bind(this);
		this.tap = this.tap.bind(this);*/

	}

	disable() {
		this.btn.alpha = 0.3;
		this.interactive = false;
	}

	enable() {
		this.btn.alpha = 1;
		this.interactive = true;
	}

	click(){
		this._soundController.play('btnPop');
		this.action();
	}
/*
	tap() {
		this.click();
	}

	mousedown() {
		this.click();
	}*/
}
