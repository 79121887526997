import {showLog} from "./helpers";
import { Tx, Output, Outpoint } from "leap-core";

export const GET_COLOR = "plasma_getColor";
export const GET_UNSPENT = "plasma_unspent";
export const RAW = "eth_sendRawTransaction";
export const GET_TX = "eth_getTransactionByHash";

export const fetchData = async endpoint => {
  return await fetch(endpoint).then(async response => {
    return await response.json().then(json => {
      return json.round;
    });
  });
};
export const postfetchData = async (endpoint, payload) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  const transport = {
    method: "POST",
    mode: "cors",
    cache: "default",
    headers,
    body: JSON.stringify(payload)
  };
  return await fetch(endpoint, transport).then(async response => {
    return await response.json().then(json => json);
  });
};

const getUnspentOutputs = async (plasma, from, color, logResult) => {
  const raw = await plasma.send(GET_UNSPENT, [from, parseInt(color)]);
  logResult &&
  showLog(raw, `Unspent Outputs for ${from} token ${color}`, "---");
  return raw.map(utxo => ({
    output: Output.fromJSON(utxo.output),
    outpoint: Outpoint.fromRaw(utxo.outpoint)
  }));
};

export const makeTransferTransaction = async options => {
  const {plasma, ...rest} = options;
  const { from, to, color, amount, logResult } = rest;
  logResult && showLog(rest,' Transfer Options');
  const utxos = await getUnspentOutputs(plasma, from, 0, true);
  const rawTx = Tx.transferFromUtxos(
    utxos,
    from,
    to,
    amount,
    parseInt(color)
  )
};

export const makeTransfer = async options => {
  const {plasma, ...rest} = options;
  const { from, to, color, amount, privateKey, logResult } = rest;
  logResult && showLog(rest,' Transfer Options');
  const utxos = await getUnspentOutputs(plasma, from, 0, true);
  const rawTx = Tx.transferFromUtxos(
    utxos,
    from,
    to,
    amount,
    parseInt(color)
  )
    .signAll(privateKey)
    .hex();
  const txHash = await plasma.send('eth_sendRawTransaction', [rawTx]);

  return txHash;
};
