import styled from 'styled-components';
import {ICON_FONT_BRANDS, ICON_FONT_FREE} from '../../theme/fonts';
import {ICON_COLOR_ACTVE, ICON_COLOR_NORMAL, LEVEL_CONTAINER_COLOR, WHITE, YELLOW} from "../../theme/colors";
import {FlexboxRow} from "../Layout";


export const IconContainer = styled(FlexboxRow)`
	color: ${ICON_COLOR_NORMAL};
	background-color: ${({ bgColor = LEVEL_CONTAINER_COLOR }) => `${bgColor}`};
	font-size: ${({ fontSize}) => fontSize ? `${fontSize}px` : 'inherit'};
	width: 50px;
    height: 50px;
	border-radius: 40px;
	margin-right: 10px;
    box-sizing: border-box;
    cursor: pointer;
    &:hover{
        color: ${ICON_COLOR_ACTVE};
    }
`;

export const BTCIcon = styled.span`
	&:before {
		content: '\\f379';
		font-family: ${ICON_FONT_BRANDS};
		font-weight: 200;
		margin-right: 0.25em;
		font-style: normal;
	}
`;

export const FacebookIcon = styled.span`
	&:before {
		content: '\\f39e';
		font-family: ${ICON_FONT_BRANDS};
		font-weight: 200;
		font-style: normal;
	    text-decoration: none;
	}
`;


export const TwitterIcon = styled.span`
	&:before {
		content: '\\f099';
		font-family: ${ICON_FONT_BRANDS};
		font-weight: 200;
		font-style: normal;
	    text-decoration: none;
	}
`;


export const ChatIcon = styled.span`
	font-size: 32px;
	&:before {
		content: '\\f3e8';
		font-family: ${ICON_FONT_BRANDS};
		font-weight: 300;
		font-style: normal;
	}
`;

export const SettingsIcon = styled.span`
	font-size: 24px;
	&:before {
		content: '\\f013';
		font-family: ${ICON_FONT_FREE};
		font-weight: 300;
		font-style: normal;
	}
`;

export const UsernameIcon = styled.span`
	color: ${({ color = WHITE }) => `${color}`};
	&:before {
		content: '\\f2bd';
		font-family: ${ICON_FONT_FREE};
		font-weight: 200;
		font-style: normal;
	    text-decoration: none;
	}
`;

export const FontAwesomeIcon = styled.span`
	color: ${({ color = WHITE }) => `${color}`};
	font-size: 20px;
	position: absolute;
	top: ${({ iconAdjust = 0 }) => `${34+iconAdjust}px`};
	left: 12px;
	&:before {
		content: ${({ code }) => { return `\'\\${code}\'`}}};
		font-family: ${ICON_FONT_FREE};
		font-weight: 200;
		font-style: normal;
	    text-decoration: none;
	}
`;
