import Pixi, { Container, Text } from 'pixi.js';

const UI_PADDING = 10;

export default class extends Container {
	constructor(label, value) {
		super();
		this.label = label;
		this.value = value;
		this.labelText = new Text(this.label + ':', {
			fontFamily: 'Ubuntu',
			fontSize: '18px',
			fontStyle: 'bold',
			fill: 'rgba(255,255,255,1)'
		});
		this.addChild(this.labelText);

		this.valueText = new Text(this.value, {
			fontFamily: 'Ubuntu',
			fontSize: '18px',
			fontStyle: 'bold',
			fill: 'rgba(255,255,255,1)'
		});
		this.addChild(this.valueText);
		this.labelWidth = this.labelText.width;
		this.updateText = this.updateText.bind(this);

		this.updateText(this.value);
	}

	updateText(newVal) {
		this.valueText.text = newVal;
		this.valueText.position.x = this.labelWidth + UI_PADDING;
	}
}
