//import { Spine } from "pixi.js";
import { TimelineMax, TweenMax } from "gsap";
//import CoinEmitter from "../particles/CoinEmitter";
import { print } from "../../../utils";

export default class CombatPlayer {
  constructor(app, hero, enemy, eventBus, camera, soundController) {
    console.log("THIS combo player");
    this.app = app;
    this.hero = hero;
    this.enemy = enemy;
    this.camera = camera;
    this.soundController = soundController;
    this._eventBus = eventBus;

    this._comboHelperHeroHit = this._comboHelperHeroHit.bind(this);
    this._comboHelperEnemyHit = this._comboHelperEnemyHit.bind(this);

    console.log(this.hero.swordEffect);
  }

  _getEnemyHeadPosition() {
    let enemyHead = this.enemy.skeleton.findBone("chest");

    return {
      x: enemyHead.worldX + 800,
      y: enemyHead.worldY / 4 + 350
    };
  }
  _getHeroHeadPosition() {
    let heroHead = this.hero.skeleton.findBone("right-arm"); // ?? right-arm?

    return {
      x: heroHead.worldX + 160,
      y: heroHead.worldY / 2 + 350
    };
  }

  _comboHelperEnemyHit(
    enemy,
    timeline,
    speed,
    timeOffset,
    damage,
    cardNum,
    combo = false,
    lastAttackCard = -1
  ) {
    const { soundController, _eventBus } = this;

    //console.log(lastAttackCard);

    /*_eventBus({
			type: 'mockDamage',
			target: 'enemy',
			value: damage
		});*/

    //const { currentHP, _turnDamage } = this.app.enemyLifeBar;
    //const death = currentHP <= _turnDamage;

    let deathDuration = enemy.state.data.skeletonData.findAnimation("death")
      .duration;
    timeline
      .add(() => {
        if (damage > 25) {
          this.camera.shake();
        }
        soundController.play("normalHit");
        _eventBus({
          type: "spawn-coins",
          position: this._getEnemyHeadPosition(),
          vector: {
            x: 2,
            y: -3
          },
          damage
        });
      }, timeOffset)
      .to(enemy, 0.1, { tint: 0xff0000 }, timeOffset)
      .to(enemy, 0.1, { tint: 0xffffff }, timeOffset + 0.1)
      .to(enemy, 0.1, { tint: 0xff0000 }, timeOffset + 0.1 * 2)
      .to(enemy, 0.1, { tint: 0xffffff }, timeOffset + 0.1 * 3)
      .add(() => {
        /*				_eventBus({
					type: 'damage',
					target: 'enemy',
					value: damage
				});*/
      }, timeOffset + 0.25);

    //console.log(`FROM WITHIN #${cardNum}=>`, lastAttackCard, combo, death);
    // TODO: bring back check that enemy is dead
    if (cardNum === lastAttackCard && combo === false) {
      timeline
        .add(() => enemy.state.setAnimation(1, "death", false), timeOffset)
        .add(() => enemy.state.clearTracks(), timeOffset + deathDuration);
    } else {
      timeline.add(() => {
        enemy.state.setAnimation(1, "hit", false);
        enemy.state.timeScale = speed;
      }, timeOffset);
    }
  }

  _comboHelperHeroHit(hero, timeline, speed, timeOffset, damage) {
    const { _eventBus, soundController } = this;
    const heroHitOffset = hero.state.data.skeletonData.findAnimation("hit-1")
      .duration;
    timeline
      .add(() => {
        if (damage > 40) {
          this.camera.shake();
        }
        soundController.play("normalHit");
        hero.state.setAnimation(1, "hit-1", false);
        /*				_eventBus({
					type: 'damage',
					target: 'player',
					value: damage
				});*/
      }, timeOffset)
      .add(
        () => hero.state.setAnimation(1, "idle", true),
        timeOffset + heroHitOffset
      )
      .to(hero, 0.1, { tint: 0xff0000 }, 0.5)
      .to(hero, 0.1, { tint: 0xffffff }, 0.5 + 0.1)
      .to(hero, 0.1, { tint: 0xff0000 }, 0.5 + 0.1 * 2)
      .to(hero, 0.1, { tint: 0xffffff }, 0.5 + 0.1 * 3);
  }
  normalParry() {
    const { hero, enemy, _eventBus, soundController, camera } = this;

    let heroDeflect = hero.state.data.skeletonData.findAnimation(
      "normal-deflected"
    ).duration;
    let enemyDeflect = enemy.state.data.skeletonData.findAnimation(
      "normal-deflected"
    ).duration;

    let tl = new TimelineMax({ delay: 0, repeat: 0 });
    tl.add(() => {
      if (hero.state.hasAnimation("normal-deflected")) {
        hero.state.setAnimation(1, "normal-deflected", false);
      }
    }, 0)
      .add(() => {
        if (hero.state.hasAnimation("idle")) {
          hero.state.setAnimation(1, "idle", true);
        }
      }, heroDeflect)
      .add(() => {
        if (enemy.state.hasAnimation("normal-deflected")) {
          enemy.state.setAnimation(1, "normal-deflected", false);
        }
      }, 0.25)
      .add(() => {
        if (enemy.state.hasAnimation("idle")) {
          enemy.state.setAnimation(1, "idle", true);
        }
      }, enemyDeflect + 0.25)
      .add(() => {
        camera.shake(0.5);
        _eventBus({
          type: "deflect",
          position: window.deflectPos || { x: 650, y: 250 }
        });
      }, window.deflectTime || 0.45)
      .add(() => {
        soundController.play("swordClash");
        _eventBus({
          type: "spawn-coins",
          position: { x: 650, y: 250 },
          vector: {
            x: 0,
            y: -4
          },
          damage: 20
        });
      }, 0.5);

    //this._comboHelperEnemyHit(enemy, tl, 1, 0.5, 20);
    return {
      timeShift: Math.max(heroDeflect, enemyDeflect + 0.25),
      timeline: tl
    };
  }
  heroAttackSuccess(cardNum, lastAttackCard) {
    const { hero, enemy, soundController } = this;
    let attackOffset = hero.state.data.skeletonData.findAnimation("attack")
      .duration;
    let hitOffset = enemy.state.data.skeletonData.findAnimation("hit").duration;
    let tl = new TimelineMax({ delay: 0, repeat: 0 });

    tl.add(() => {
      if (hero.state.hasAnimation("attack")) {
        hero.state.setAnimation(1, "attack", false);
      }
    }).add(() => {
      if (hero.state.hasAnimation("idle")) {
        hero.state.setAnimation(1, "idle", true);
      }
    }, attackOffset);

    this._comboHelperEnemyHit(
      enemy,
      tl,
      1,
      0.5,
      50,
      cardNum,
      false,
      lastAttackCard
    );

    if (!this.app.enemy.shouldDie && cardNum !== 0) {
      tl.add(() => {
        enemy.state.setAnimation(1, "idle", true);
      }, 0.5 + hitOffset);
    }

    print(attackOffset, hitOffset + 1);

    return {
      timeShift: Math.max(attackOffset, hitOffset + 0.5),
      timeline: tl
    };
  }
  heroJumpAndCombo4(num, lastAttackCard) {
    console.log(lastAttackCard);
    const { hero, enemy } = this;
    let tl = new TimelineMax({ delay: 0, repeat: 0 });
    let jumpOffset = hero.state.data.skeletonData.findAnimation(
      "jump-to-fight-no-translate"
    ).duration;
    let attackOffset = hero.state.data.skeletonData.findAnimation("combo-4")
      .duration;
    let jumpDistance = 280;

    const dtHit1 = jumpOffset + 0.35;
    const dtHit2 = jumpOffset + 1;
    const dtHit3 = jumpOffset + 1.75;
    const dtHit4 = jumpOffset + attackOffset - 0.9;

    tl
      // JUMP FORWARD
      .add(() => {
        if (hero.state.hasAnimation("jump-to-fight-no-translate")) {
          hero.state.setAnimation(1, "jump-to-fight-no-translate", false);
          hero.state.timeScale = 1;
        }
      })
      .to(hero.position, 0.75, { x: "+=" + jumpDistance }, 0.25)
      // ATTACK
      .add(() => {
        if (hero.state.hasAnimation("combo-4")) {
          // run forever
          hero.state.setAnimation(1, "combo-4", false);
          hero.state.timeScale = 1;
        }
      })

      // JUMP BACK
      .add(() => {
        if (hero.state.hasAnimation("jump-to-fight-no-translate")) {
          hero.state.setAnimation(1, "jump-to-fight-no-translate", false);
          hero.state.timeScale = 1;
        }
      }, jumpOffset + attackOffset - 0.25)
      .to(
        hero.position,
        0.5,
        { x: "-=" + jumpDistance },
        jumpOffset + attackOffset
      )

      // BACK TO IDLE
      .add(() => {
        if (hero.state.hasAnimation("idle")) {
          // run forever
          hero.state.setAnimation(1, "idle", true);
          hero.state.timeScale = 1;
        }
      }, jumpOffset + attackOffset + 0.5);

    if (this.app.enemy.shouldDie === false) {
      //console.log(cardNum, this.app.enemy.shouldDie);
      tl.add(() => {
        enemy.state.setAnimation(1, "idle", true);
        enemy.state.timeScale = 1;
      }, dtHit4 + 0.65);
    }

    this._comboHelperEnemyHit(
      enemy,
      tl,
      1.5,
      dtHit1,
      15,
      num,
      true,
      lastAttackCard
    );
    this._comboHelperEnemyHit(
      enemy,
      tl,
      1.5,
      dtHit2,
      20,
      num,
      true,
      lastAttackCard
    );
    this._comboHelperEnemyHit(
      enemy,
      tl,
      1.5,
      dtHit3,
      25,
      num,
      true,
      lastAttackCard
    );
    this._comboHelperEnemyHit(
      enemy,
      tl,
      1.5,
      dtHit4,
      40,
      num,
      false,
      lastAttackCard
    );

    return {
      timeShift: jumpOffset + attackOffset + 0.5,
      timeline: tl
    };
  }
  heroJumpAndCombo4Mk2() {
    const { hero, enemy } = this;
    let tl = new TimelineMax({ delay: 0, repeat: 0 });
    let jumpOffset = hero.state.data.skeletonData.findAnimation(
      "jump-to-fight-no-translate"
    ).duration;
    let attackOffset = hero.state.data.skeletonData.findAnimation("combo-4")
      .duration;
    let deathDuration = enemy.state.data.skeletonData.findAnimation("death")
      .duration;

    let jumpDistance = 280;

    const dtHit1 = jumpOffset + 0.35;
    const dtHit2 = jumpOffset + 1;
    const dtHit3 = jumpOffset + 1.75;
    const dtHit4 = jumpOffset + attackOffset - 0.9;

    tl
      // JUMP FORWARD
      .add(() => {
        if (hero.state.hasAnimation("jump-to-fight-no-translate")) {
          hero.state.setAnimation(1, "jump-to-fight-no-translate", false);
          hero.state.timeScale = 1;
        }
      })
      .to(hero.position, 0.75, { x: "+=" + jumpDistance }, 0.25)
      // ATTACK
      .add(() => {
        if (hero.state.hasAnimation("combo-4")) {
          // run forever
          hero.state.setAnimation(1, "combo-4", false);
          hero.state.timeScale = 1;
        }
      })
      // JUMP BACK
      .add(() => {
        if (hero.state.hasAnimation("jump-to-fight-no-translate")) {
          hero.state.setAnimation(1, "jump-to-fight-no-translate", false);
          hero.state.timeScale = 1;
        }
      }, jumpOffset + attackOffset - 0.25)
      .to(
        hero.position,
        0.5,
        { x: "-=" + jumpDistance },
        jumpOffset + attackOffset
      )

      // BACK TO IDLE
      .add(() => {
        if (hero.state.hasAnimation("idle")) {
          // run forever
          hero.state.setAnimation(1, "idle", true);
          hero.state.timeScale = 1;
        }
      }, jumpOffset + attackOffset + 0.5);

    this._comboHelperEnemyHit(enemy, tl, 1.5, dtHit1, 15);
    this._comboHelperEnemyHit(enemy, tl, 1.5, dtHit2, 20);
    this._comboHelperEnemyHit(enemy, tl, 1.5, dtHit3, 25);
    this._comboHelperEnemyHit(enemy, tl, 1.5, dtHit4, 50, true);
    //this._comboHelperEnemyHit(enemy, tl, 1.5, dtHit4, 50);
  }
  heroJumpAttackDefense() {
    const { hero, enemy, soundController } = this;
    let tl = new TimelineMax({ delay: 0, repeat: 0 });
    let jumpOffset = hero.state.data.skeletonData.findAnimation(
      "jump-to-fight-no-translate"
    ).duration;
    let attackOffset = hero.state.data.skeletonData.findAnimation("attack")
      .duration;
    let laughOffset = enemy.state.data.skeletonData.findAnimation("laughter")
      .duration;
    let jumpDistance = 230;

    const effectOffset = window.effectOffset || 0.3;

    tl
      // JUMP FORWARD
      .add(() =>
        hero.state.setAnimation(1, "jump-to-fight-no-translate", false)
      )
      .to(hero.position, 0.6, { x: "+=" + jumpDistance }, 0.25)
      .add(() => hero.state.setAnimation(1, "attack", false), jumpOffset)
      .add(() => {
        hero.swordEffect.visible = 1;
        hero.swordEffect.skeleton.setToSetupPose();
        hero.swordEffect.state.setAnimation(1, "animation", false);
      }, jumpOffset + effectOffset)
      .add(
        () => hero.state.setAnimation(1, "attack", false),
        jumpOffset + attackOffset
      )
      .add(() => {
        hero.swordEffect.skeleton.setToSetupPose();
        hero.swordEffect.state.setAnimation(1, "animation", false);
      }, jumpOffset + attackOffset + effectOffset)
      .add(
        () => hero.state.setAnimation(1, "jump-to-fight-no-translate", false),
        jumpOffset + attackOffset * 2 + 0.2
      )
      .to(
        hero.position,
        0.6,
        { x: "-=" + jumpDistance },
        jumpOffset + attackOffset * 2 + 0.4
      )
      .add(
        () => hero.state.setAnimation(1, "idle", true),
        jumpOffset * 2 + attackOffset * 2 + 0.1
      )

      .add(
        () => enemy.state.setAnimation(1, "defend", false),
        jumpOffset + 0.25
      )
      .add(
        () => enemy.state.setAnimation(1, "defend", false),
        jumpOffset + attackOffset + 0.5
      )
      .add(
        () => enemy.state.setAnimation(1, "idle", true),
        jumpOffset + attackOffset + 1.5
      )

      .add(
        () => enemy.state.setAnimation(1, "laughter", false),
        jumpOffset * 2 + attackOffset * 2 + 0.1
      )
      .add(
        () => enemy.state.setAnimation(1, "idle", true),
        jumpOffset * 2 + attackOffset * 2 + 0.1 + laughOffset
      );

    return {
      timeShift: 6.35,
      timeline: tl
    };
  }
  enemyLaugh() {
    const { enemy, soundController } = this;
    let tl = new TimelineMax({ delay: 0, repeat: 0 });
    let laughOffset = enemy.state.data.skeletonData.findAnimation("laughter")
      .duration;
    tl
      // JUMP FORWARD

      .add(() => enemy.state.setAnimation(1, "laughter", false), 0)
      .add(() => console.log() /*soundController.laugh.play('triple')*/, 0)
      .add(() => enemy.state.setAnimation(1, "idle", true), laughOffset);
  }
  heroAttackEnemyDefend() {
    // Animation Lengths
    // hero attack: 0.83
    // enemy defend: 1
    // last frame to idle: 1.4
    const { hero, enemy, soundController } = this;
    let tl = new TimelineMax({ delay: 0, repeat: 0 });
    tl.add(() => {
      if (hero.state.hasAnimation("attack")) {
        // run forever
        hero.state.setAnimation(1, "attack", false);
        hero.state.timeScale = 1;
      }
    }, 0)
      .add(() => {
        if (hero.state.hasAnimation("idle")) {
          // run forever
          hero.state.setAnimation(1, "idle", true);
          hero.state.timeScale = 1;
        }
      }, "0.85")

      // ENEMY Animations
      .add(() => {
        if (enemy.state.hasAnimation("defend")) {
          // run forever
          enemy.state.setAnimation(1, "defend", false);
          enemy.state.timeScale = 1;
        }
      }, 0.4)
      .add(() => {
        if (enemy.state.hasAnimation("idle")) {
          // run forever
          enemy.state.setAnimation(1, "idle", true);
          enemy.state.timeScale = 1;
        }
      }, 1.4)
      .add(() => soundController.play("shieldHit"), 0.5);

    return {
      timeShift: 1.4,
      timeline: tl
    };
  }
  heroDefendEnemyAttack() {
    const { hero, enemy, soundController, _eventBus } = this;
    let tl = new TimelineMax();
    let heroDefenseOffset = hero.state.data.skeletonData.findAnimation("defend")
      .duration;
    tl.add(() => hero.state.setAnimation(1, "defend", false), 0.2)
      .add(
        () => hero.state.setAnimation(1, "idle", true),
        heroDefenseOffset + 0.1
      )
      .add(() => soundController.play("shieldHit"), 0.3)
      .add(
        () =>
          _eventBus({
            type: "spawn-coins",
            position: this._getHeroHeadPosition(),
            vector: {
              x: -3,
              y: -2
            }
          }),
        0.3
      )
      .add(() => {
        if (enemy.state.hasAnimation("normal-deflected")) {
          enemy.state.setAnimation(1, "normal-deflected", false);
        }
      }, 0)
      .add(() => {
        if (enemy.state.hasAnimation("idle")) {
          enemy.state.setAnimation(1, "idle", true);
        }
      }, 1.25);

    return {
      timeShift: 1.4,
      timeline: tl
    };
  }
  heroDefendEnemyStrongAttack() {
    const { hero, enemy, soundController, _eventBus } = this;
    let heroDefenseOffset = hero.state.data.skeletonData.findAnimation("defend")
      .duration;
    let enemyAttackOffset = enemy.state.data.skeletonData.findAnimation(
      "heavy-swing"
    ).duration;
    let tl = new TimelineMax();
    tl.add(() => hero.state.setAnimation(1, "defend", false), 0.1)
      .add(
        () => hero.state.setAnimation(1, "idle", true),
        heroDefenseOffset + 0.1
      )
      .add(() => soundController.play("shieldHit"), 0.3)
      .add(() => this.camera.shake(), 0.3)
      .add(
        () =>
          _eventBus({
            type: "spawn-coins",
            position: this._getHeroHeadPosition(),
            vector: {
              x: -3,
              y: -2
            }
          }),
        0.3
      )
      .add(() => enemy.state.setAnimation(1, "heavy-swing", false), 0)
      .add(() => enemy.state.setAnimation(1, "idle", true), enemyAttackOffset);

    return {
      timeShift: 1.8,
      timeline: tl
    };
  }
  heroDefendEnemyLaugh() {
    const { hero, enemy, soundController } = this;
    let heroDefenseOffset = hero.state.data.skeletonData.findAnimation("defend")
      .duration;
    let laughOffset = enemy.state.data.skeletonData.findAnimation("laughter")
      .duration;
    let tl = new TimelineMax();
    tl.add(() => hero.state.setAnimation(1, "defend", false), 0)
      .add(() => hero.state.setAnimation(1, "idle", true), heroDefenseOffset)
      .add(() => enemy.state.setAnimation(1, "laughter", false), 0.4)
      .add(() => console.log() /*soundController.laugh.play('triple')*/, 0.4)
      .add(() => enemy.state.setAnimation(1, "idle", true), laughOffset + 0.4);
    return { timeShift: laughOffset + 0.4, timeline: tl };
  }
  heroComboAnimated() {
    const { hero, enemy, soundController } = this;
    let tl = new TimelineMax({ delay: 0, repeat: 0 });
    tl.add(() => {
      if (hero.state.hasAnimation("combo-4")) {
        // run forever
        hero.state.setAnimation(1, "combo-4", false);
        hero.state.timeScale = 1;
      }
    }).add(() => {
      if (hero.state.hasAnimation("idle")) {
        // run forever
        hero.state.setAnimation(1, "idle", true);
        hero.state.timeScale = 1;
      }
    }, "+=3.3");
  }
  heroCombo() {
    const { hero, enemy, soundController } = this;
    let tl = new TimelineMax({ delay: 0, repeat: 0 });

    tl.add(() => {
      if (hero.state.hasAnimation("attack")) {
        // run forever
        hero.state.setAnimation(1, "attack", false);
        hero.state.timeScale = 1;
      }
    }, "+=0.85")
      .add(() => {
        if (hero.state.hasAnimation("attack")) {
          // run forever
          hero.state.setAnimation(1, "attack", false);
          hero.state.timeScale = 1;
        }
      }, "+=0.85")
      .add(() => {
        if (hero.state.hasAnimation("attack")) {
          // run forever
          hero.state.setAnimation(1, "attack", false);
          hero.state.timeScale = 1;
        }
      }, "+=0.85")
      .add(() => {
        if (hero.state.hasAnimation("idle")) {
          // run forever
          hero.state.setAnimation(1, "idle", true);
          hero.state.timeScale = 1;
        }
      }, "+=0.85");
  }
  enemyStrongAttackSuccess() {
    const { hero, enemy } = this;
    let enemyAttackOffset = enemy.state.data.skeletonData.findAnimation(
      "heavy-swing"
    ).duration;
    let tl = new TimelineMax({ delay: 0, repeat: 0 });
    tl.add(() => enemy.state.setAnimation(1, "heavy-swing", false))
      .add(() => enemy.state.setAnimation(1, "idle", true), enemyAttackOffset)
      .add(() => this.camera.shake(), 0.5);

    this._comboHelperHeroHit(hero, tl, 1, 0.5, 100);
    return {
      timeShift: enemyAttackOffset + 0.2,
      timeline: tl
    };
  }
  enemyAttackSuccess() {
    const { hero, enemy } = this;
    let enemyAttackOffset = enemy.state.data.skeletonData.findAnimation(
      "spear-test"
    ).duration;
    const heroHitOffset = hero.state.data.skeletonData.findAnimation("hit-1")
      .duration;
    let tl = new TimelineMax({ delay: 0, repeat: 0 });
    tl.add(() => enemy.state.setAnimation(1, "spear-test", false)).add(
      () => enemy.state.setAnimation(1, "idle", true),
      enemyAttackOffset
    );

    this._comboHelperHeroHit(hero, tl, 1, 0.25, 20);
    return {
      timeShift: Math.max(enemyAttackOffset, heroHitOffset + 0.25),
      timeline: tl
    };
  }

  enemyDeath() {
    const { enemy } = this;
    let deathDuration = enemy.state.data.skeletonData.findAnimation("death")
      .duration;

    let tl = new TimelineMax({ delay: 0, repeat: 0 });
    tl.add(() => enemy.state.setAnimation(1, "death", false)).add(
      () => enemy.state.clearTracks(),
      deathDuration
    );
  }

  update(dt) {
    if (!this._pauseAnim) {
      this.hero.update(dt);
      this.enemy.update(dt);
    }
  }
}
