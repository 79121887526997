import { TimelineMax } from 'gsap';
export default class Camera{
	constructor(camera){
		this.camera = camera;
	}

	shake(power = 1) {
		let tl = new TimelineMax({ delay: 0, repeat: 0 });
		const {camera} = this;
		tl
			.set(camera.position, { x: power * 1, y: power * 10 }, 0)
			.to(camera.position, 0.1, { x: power * -2, y: power * -4 }, '+=0.1')
			.to(camera.position, 0.1, { x: power * 0.5, y: power * 3 }, '+=0.1')
			.to(camera.position, 0.1, { x: power * -0.25, y: power * -1 }, '+=0.1')
			.to(camera.position, 0.1, { x: power * 0, y: power * 0 }, '+=0.1');
	}
}