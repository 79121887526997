import { Container, Sprite, Graphics } from "pixi.js";
import { TimelineLite, Power2 } from "gsap";

const FLIP_TIME = 0.2,
  TRANSITION_TIME = 0.35,
  FLIP_START_TIME = TRANSITION_TIME - FLIP_TIME;

export default class Card extends Container {
  constructor(config, resources, eventBus, parent) {
    super();
    const { position, scale, card } = config;
    this.position = position;
    this._initPosition = position;
    this._uScale = scale;
    this.cardType = card.type;
    this._eventBus = eventBus;
    this.cardIndex = config.cardIndex;
    this.app = parent;

    this.front = new Sprite(resources["card" + card.type].texture);
    this.front.anchor.x = 0.5;
    this.front.scale.x = 0;

    this.back = new Sprite(resources.cardBack.texture);
    this.back.anchor.x = 0.5;

    this.addChild(this.front);
    this.addChild(this.back);

    this.timeLine = new TimelineLite();
    this._flipp = 0;
    this.interactive = true;

    this.flipFrontBack = this.flipFrontBack.bind(this);
    this.flipBackFront = this.flipBackFront.bind(this);

    const frameWidth = 140;
    const frameHeight = 235;
    this.bbFrame = new Graphics();
    this.bbFrame.lineStyle(5, 0xffff00);
    this.bbFrame.drawRoundedRect(
      -frameWidth / 2,
      5,
      frameWidth,
      frameHeight,
      5
    );
    this.bbFrame.endFill();
    this.bbFrame.alpha = 0;
    this.selected = false;

    //console.log(this.width, this.height);
    this.addChild(this.bbFrame);
  }

  get uniformScale() {
    return this._uScale;
  }

  set uniformScale(val) {
    this.scale = {
      x: val,
      y: val
    };
    this._uScale = val;
  }

  get _flip() {
    return this._flipp;
  }

  set _flip(newValue) {
    this._flipp = newValue;
    if (newValue < 50) {
      this.front.scale.x = 0;
      this.back.scale.x = (50 - newValue) / 50;
    } else {
      this.back.scale.x = 0;
      this.front.scale.x = (newValue - 50) / 50;
    }
  }

  flipBackFront() {
    const { _soundController } = this.app._app.app; // TODO: Fix this stupid construct :D
    _soundController.play("cardPlaced");
    this.timeLine.to(this, FLIP_TIME, { ease: Power2.easeOut, _flip: 100 });
  }

  flipFrontBack() {
    this.timeLine.to(this, FLIP_TIME, { ease: Power2.easeOut, _flip: 0 });
  }

  toggleCardState() {
    if (this._flip === 100) {
      this.flipFrontBack();
    } else {
      this.flipBackFront();
    }
  }

  markForSwap() {
    if (this.app.mode !== "play") {
      if (this.bbFrame.alpha == 1) {
        this.selected = false;
        this.bbFrame.alpha = 0;
        this.timeLine.to(this, FLIP_TIME, {
          ease: Power2.easeOut,
          y: this._initPosition.y
        });
      } else {
        this.bbFrame.alpha = 1;
        this.selected = true;
        this.timeLine.to(this, FLIP_TIME, {
          ease: Power2.easeOut,
          y: this._initPosition.y - 25
        });
      }
    }
  }

  clearSelection() {
    //console.log("Clear me!");
    this.selected = false;
    this.bbFrame.alpha = 0;
    this.y = this._initPosition.y;
  }

  mousedown() {
    this.markForSwap();
    this._eventBus({
      type: "card-clicked",
      card: this
    });
  }

  tap() {
    this.markForSwap();
    this._eventBus({
      type: "card-clicked",
      card: this
    });
  }

  mouseover() {
    //console.log(`Card type => ${this.cardType}`);
  }

  /*	mouseover() {
		this.timeLine.to(this, FLIP_TIME, {
			ease: Power2.easeOut,
			y: this._initPosition.y - 25
		});
	}

		mouseout() {
		if (!this.selected) {
			this.timeLine.to(this, 0.5, {
				ease: Elastic.easeOut.config(1, 0.3),
				y: this._initPosition.y
			});
		}
	}*/
}
