import styled from 'styled-components';
import {
	BALANCE_COLOR,
	HEADER_BG_COLOR,
	HEADER_BOTTOM_BG_COLOR, HEADER_LINK_ACTIVE, HEADER_LINK_NORMAL,
	HEADER_TOP_BG_COLOR, LEVEL_CONTAINER_COLOR
} from '../../theme/colors';
import { NavLink } from 'react-router-dom';
import {FlexboxColumn, FlexboxRow} from '../Layout';
import {DISPLAY_FONT} from "../../theme/fonts";
import {YELLOW, USERNAME_COLOR} from "../../theme/colors";
import {ICON_FONT_BRANDS} from "../../theme/fonts";

const activeClassName = 'nav-link_active';

export const BigHeaderContainer = styled.div`
	width: 100%;
	background-color: ${HEADER_BG_COLOR};
	position: relative;
`;

export const Logo = styled.img`
	width: auto;
	height: 100px;
`;

export const TopPart = styled(FlexboxRow)`
	padding: 10px 20px;
	justify-content: flex-start;
	background-color: ${HEADER_TOP_BG_COLOR};
	box-sizing: border-box;
`;

export const Username = styled.div`
	font-size: 12px;
	color: ${USERNAME_COLOR};
	margin-bottom: 5px;
	& span{
		font-weight: 800;
	}
`;
export const Balance = styled.div`
	color: ${BALANCE_COLOR};
	span{
	  margin-left: 5px;
	  font-weight: bold;
	}
`;

export const LinkContainer = styled(FlexboxRow)`
	justify-content: space-between;
	padding: 0 20px;
	box-sizing: border-box;
	background-color: ${HEADER_BOTTOM_BG_COLOR};
`;
export const HeaderLink = styled(NavLink).attrs({
	activeClassName
})`
	display: inline-block;
	text-transform: uppercase;
	margin-right: 1.5em;
	cursor: pointer;
	text-decoration: none;
	color: ${HEADER_LINK_NORMAL};
	font-size: 12px;

	&.logo {
		position: absolute;
		top: 5px;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 200px;
		text-align: center;
	}

	&:not(.logo) {
		padding: 16px 10px;
	}
	&:last-child {
		margin-right: 0;
	}
	&.${activeClassName}:not(.logo),
	&:hover:not(.logo) {
		box-shadow: inset 0 -3px ${HEADER_LINK_ACTIVE};
		color: ${HEADER_LINK_ACTIVE};
	}
`;

export const LevelContainer = styled(FlexboxColumn)`
	color: ${YELLOW};
	background-color: ${LEVEL_CONTAINER_COLOR};
	width: 50px;
    height: 50px;
	border-radius: 40px;
	margin-right: 20px;
    box-sizing: border-box;
	
	& p{
		margin: 0;
		padding: 0;
	}
`;

export const LevelLabel = styled.p`
	font-size: 11px;
	text-transform: uppercase;
	text-align: center;
`;
export const LevelValue = styled.p`
	font-family: ${DISPLAY_FONT};
	font-size: 20px;
	text-align: center;
    letter-spacing: 1px;
`;

export const UserInfo = styled.div`
	justify-content: flex-start;
`;
