import { Sprite, Container, Graphics, Text } from 'pixi.js';
import { TimelineMax, TweenMax } from 'gsap';

const LIFE_PADDING = 4;
export default class LifeBar extends Container {
	constructor(options, app) {
		super();
		this.app = app;
		console.log(options);

		this._turnDamage = 0;
		this._height = options.height;
		this._width = options.width;

		this._totalHP = options.totalHP;
		this._currentHP = options.currentHP;
		this._name = options.name;

		this._blackUnderlay = new Graphics();
		this._blackUnderlay.beginFill(0x000000);
		this._blackUnderlay.drawRect(0, 0, options.width, options.height);
		this._blackUnderlay.endFill();

		const initPercentage = options.currentHP / options.totalHP;
		console.log(initPercentage);

		this._fillLayer = new Sprite(
			this.app.resources['hp-' + options.color].texture
		);
		this._fillLayer.height = options.height - LIFE_PADDING * 2;
		this._fillLayer.width = options.width * initPercentage - LIFE_PADDING * 2;
		this._fillLayer.position = {
			x: LIFE_PADDING,
			y: LIFE_PADDING
		};

		this._labelHP = new Text(`${this._name} : ${options.currentHP} / ${options.totalHP}`, {
			fontFamily: 'Arial',
			fontStyle: 'Bold',
			fontSize: 16,
			fill: 0xffffff,
			align: 'center'
		});
		this.addChild(this._labelHP);

		const w1 = this._fillLayer.width;
		const w2 = this._labelHP.width;
		const nX = w2 - w1;//w1 > w2 ? w1/2-w2/2 : w2/2-w1/2;

		this._labelHP.position = {
			x: nX,
			y: -30
		};

		this.addChild(this._blackUnderlay);
		this.addChild(this._fillLayer);
	}

	set currentHP(newValue) {
		let tl = new TimelineMax();
		let diff = this._currentHP - newValue;
		this._currentHP = newValue;
		let newPercentage = newValue / this._totalHP;
		console.log(newPercentage);

		let text = new Text(diff, {
			fontFamily: 'Arial',
			fontStyle: 'Bold',
			fontSize: 24,
			fill: 0xffffff,
			align: 'center'
		});
		text.position = {
			x: this._width / 2,
			y: -this._height / 2 - 10
		};

		this.addChild(text);

		this._labelHP.text = `${this._name} : ${newValue} / ${this._totalHP}`;

		tl
			.to(
				this._fillLayer,
				0.5,
				{ width: this._width * newPercentage - LIFE_PADDING * 2 },
				0
			)
			.to(text.position, 0.5, { y: -50 }, 0)
			.add(() => {
				this.removeChild(text);
			}, 0.5);
	}

	get currentHP() {
		return this._currentHP;
	}
}
