import styled from 'styled-components';
import {PRIMARY_FONT} from "../../theme/fonts";

export const BigContainer = styled.div`
	font-family: ${PRIMARY_FONT};
  font-weight: 600;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
`;

export const Flexbox = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex: ${({ flex = 'initial' }) => flex};
  justify-content: ${({ justify = 'center' }) => justify};
  align-items: ${({ align = 'center' }) => align};
  width: ${({ width = "100%" }) => `${width}`};
  margin-bottom: ${({ marginBottom = 0 }) => `${marginBottom}px`};
  padding: ${({ padding = 0 }) => padding};
  background-color: ${({ bgColor = "transparent" }) => `${bgColor}`};
`;

export const FlexboxColumn = styled(Flexbox)`
  flex-direction: column;
  margin-right: ${({ marginRight = 0 }) => `${marginRight}px`};
`;

export const FlexColumnCentered = styled(FlexboxColumn)`
  margin: auto;
  padding: ${({ padding = 0 }) => padding};
  justify-content: ${({ justify = 'center' }) => justify};
  align-items: ${({ align = 'center' }) => align};
  text-align: center;
`;

export const FlexboxRow = styled(Flexbox)`
  flex-direction: row;
  margin-bottom: ${({ marginBottom = 0 }) => `${marginBottom}px`};
`;

export const FullScreenContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-image: url(${({ bgImage }) => bgImage});
  padding: 20px;
  box-sizing: border-box;
  
  background-color: ${({ bgColor = 'transparent' }) => bgColor};
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FullscreenForm = styled(FullScreenContainer)`
	
`;

export const FlexColLeft = styled(FlexboxColumn)`
	align-items: flex-start;
`;

export const FlexColRight = styled(FlexboxColumn)`
	align-items: flex-end;
`;


export const FlexRowLeft = styled(FlexboxRow)`
	justify-content: flex-start;
`;

export const FlexRowRight = styled(FlexboxRow)`
	justify-content: flex-end;
`;
