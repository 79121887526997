export const slotAssets = [
	{
		description: 'slotVoid',
		assetName: 'slotVoid',
		fileName: 'images/slot-symbols/slot-symbol-void.png'
	},
	{
		description: 'slotAttack',
		assetName: 'slotAttack',
		fileName: 'images/slot-symbols/slot-symbol-attack.png'
	},
	{
		description: 'slotWild',
		assetName: 'slotWild',
		fileName: 'images/slot-symbols/slot-symbol-wild.png'
	},
	{
		description: 'slotDefense',
		assetName: 'slotDefense',
		fileName: 'images/slot-symbols/slot-symbol-defense.png'
	},
	{
		description: 'slotMana',
		assetName: 'slotMana',
		fileName: 'images/slot-symbols/slot-symbol-mana.png'
	},
	{
		description: 'slotStrong',
		assetName: 'slotStrong',
		fileName: 'images/slot-symbols/slot-symbol-strong.png'
	}
];
export const cardAssets = [
	{
		description: 'cardBack',
		assetName: 'cardBack',
		fileName: 'images/cards/card-back.png'
	},
	{
		description: 'cardAttack',
		assetName: 'cardAttack',
		fileName: 'images/cards/card-attack.png'
	},
	{
		description: 'cardStrong',
		assetName: 'cardStrong',
		fileName: 'images/cards/card-strong-attack.png'
	},
	{
		description: 'cardDefense',
		assetName: 'cardDefense',
		fileName: 'images/cards/card-shield.png'
	},
	{
		description: 'cardMana',
		assetName: 'cardMana',
		fileName: 'images/cards/card-mana.png'
	},
	{
		description: 'cardDraw',
		assetName: 'cardDraw',
		fileName: 'images/cards/card-draw.png'
	},
	{
		description: 'cardVoid',
		assetName: 'cardVoid',
		fileName: 'images/cards/card-void.png'
	},
	{
		description: 'cardCurse',
		assetName: 'cardCurse',
		fileName: 'images/cards/card-curse.png'
	},
	{
		description: 'cardWild',
		assetName: 'cardWild',
		fileName: 'images/cards/card-wild.png'
	}
];
export const uiAssets = [
	{
		description: 'btnRightActive',
		assetName: 'btnRightActive',
		fileName: 'images/ui/btn-right-active.jpg'
	},
	{
		description: 'btnRightInactive',
		assetName: 'btnRightInactive',
		fileName: 'images/ui/btn-right-inactive.jpg'
	},
	{
		description: 'btnPlusActive',
		assetName: 'btnPlusActive',
		fileName: 'images/ui/btn-plus-active.png'
	},
	{
		description: 'btnPlusInactive',
		assetName: 'btnPlusInactive',
		fileName: 'images/ui/btn-plus-inactive.png'
	},
	{
		description: 'btnLeftActive',
		assetName: 'btnLeftActive',
		fileName: 'images/ui/btn-left-active.jpg'
	},
	{
		description: 'btnLeftInactive',
		assetName: 'btnLeftInactive',
		fileName: 'images/ui/btn-left-inactive.jpg'
	},
	{
		description: 'btnMinusActive',
		assetName: 'btnMinusActive',
		fileName: 'images/ui/btn-minus-active.png'
	},
	{
		description: 'btnMinusInactive',
		assetName: 'btnMinusInactive',
		fileName: 'images/ui/btn-minus-inactive.png'
	},
	{
		description: 'btnDealStart',
		assetName: 'btnDealStart',
		fileName: 'images/ui/btn-deal-start.png'
	},
	{
		description: 'btnDealProcess',
		assetName: 'btnDealProcess',
		fileName: 'images/ui/btn-deal-process.png'
	},
	{
		description: 'btnDealNoBet',
		assetName: 'btnDealNoBet',
		fileName: 'images/ui/btn-deal-make-bet.png'
	},
	{
		description: 'hp',
		assetName: 'hp-green',
		fileName: 'images/ui/hp-bar-green.png'
	},
	{
		description: 'hp',
		assetName: 'hp-red',
		fileName: 'images/ui/hp-bar-red.png'
	}
];
export const bgAssets = [
	{
		description: 'wall',
		assetName: 'wall-tile',
		fileName: 'images/wall-tile.jpg'
	},
	{
		description: 'ground',
		assetName: 'ground-tile',
		fileName: 'images/ground-tile.jpg'
	},
	{ description: 'sink', assetName: 'sink', fileName: 'images/sink.png' },
	{ description: 'door', assetName: 'door', fileName: 'images/door.png' },
	{ description: 'column', assetName: 'column', fileName: 'images/column.png' },
	{
		description: 'torch',
		assetName: 'torch',
		fileName: 'images/spine/torch/torch.json'
	},
	{ description: 'banner', assetName: 'banner', fileName: 'images/banner.png' },
	{
		description: 'chain',
		assetName: 'chain',
		fileName: 'images/spine/chain.json'
	}
];
export const effectsAssets = [
	{ description: 'coin', assetName: 'coin', fileName: 'images/coin.png' },
	{
		description: 'deflect',
		assetName: 'deflect',
		fileName: 'images/spine/deflect/deflect.json'
	}
];
export const heroAssets = [
	{
		description: 'girl',
		assetName: 'girl',
		fileName: 'images/spine/skarlet/skarlet.json'
	},
	{
		description: 'swish',
		assetName: 'swish',
		fileName: 'images/spine/swish-effect/swish.json'
	}
];
export const enemyAssets = [
	{
		description: 'skelet',
		assetName: 'skelet',
		fileName: 'images/spine/skelet-warrior/enemy.json'
	}
];
export const inventoryAssets = [
	// WEAPONS
	{
		description: 'icon-common-sword',
		assetName: 'icon-common-sword',
		fileName: 'images/inventory/icn-common-sword.png'
	},
	{
		description: 'icon-epic-sword',
		assetName: 'icon-epic-sword',
		fileName: 'images/inventory/icn-epic-sword.png'
	},
	{
		description: 'icon-rare-axe',
		assetName: 'icon-rare-axe',
		fileName: 'images/inventory/icn-rare-axe.png'
	},

	// BOOTS
	{
		description: 'icon-boots-epic',
		assetName: 'icon-boots-epic',
		fileName: 'images/inventory/icn-boots-epic.png'
	},
	{
		description: 'icon-boots-rare',
		assetName: 'icon-boots-rare',
		fileName: 'images/inventory/icn-boots-rare.png'
	},

	// SHIELDS
	{
		description: 'icon-common-shield',
		assetName: 'icon-common-shield',
		fileName: 'images/inventory/icn-common-shield.png'
	},
	{
		description: 'icon-epic-shield',
		assetName: 'icon-epic-shield',
		fileName: 'images/inventory/icn-epic-shield.png'
	},
	{
		description: 'icon-rare-shield',
		assetName: 'icon-rare-shield',
		fileName: 'images/inventory/icn-rare-shield.png'
	},

	// SHOULDERS
	{
		description: 'icon-shoulder-common',
		assetName: 'icon-shoulder-common',
		fileName: 'images/inventory/icn-shoulder-common.png'
	},
	{
		description: 'icon-shoulder-epic',
		assetName: 'icon-shoulder-epic',
		fileName: 'images/inventory/icn-shoulder-epic.png'
	},

	// HATS
	{
		description: 'icon-common-hat.png',
		assetName: 'icon-common-hat',
		fileName: 'images/inventory/icn-common-hat.png'
	},
	{
		description: 'icon-epic-hat.png',
		assetName: 'icon-epic-hat',
		fileName: 'images/inventory/icn-epic-hat.png'
	},

	// CHESTS
	{
		description: 'icon-chest-rare.png',
		assetName: 'icon-chest-rare',
		fileName: 'images/inventory/icn-chest-rare.png'
	},
	{
		description: 'icon-chest-epic.png',
		assetName: 'icon-chest-epic',
		fileName: 'images/inventory/icn-chest-epic.png'
	},

	// HAIRS
	{
		description: 'hair',
		assetName: 'icon-hair-red',
		fileName: 'images/inventory/icn-hair-red.png'
	},
	{
		description: 'hair',
		assetName: 'icon-hair-yellow',
		fileName: 'images/inventory/icn-hair-yellow.png'
	},
	{
		description: 'hair',
		assetName: 'icon-hair-green',
		fileName: 'images/inventory/icn-hair-green.png'
	},
	{
		description: 'hair',
		assetName: 'icon-hair-blue',
		fileName: 'images/inventory/icn-hair-blue.png'
	},
	{
		description: 'hair',
		assetName: 'icon-hair-violet',
		fileName: 'images/inventory/icn-hair-violet.png'
	},
	{
		description: 'hair',
		assetName: 'icon-hair-plum',
		fileName: 'images/inventory/icn-hair-plum.png'
	},

	// EYES
	{
		description: 'eye',
		assetName: 'icon-eye-red',
		fileName: 'images/inventory/icn-eye-red.png'
	},
	{
		description: 'eye',
		assetName: 'icon-eye-yellow',
		fileName: 'images/inventory/icn-eye-yellow.png'
	},
	{
		description: 'eye',
		assetName: 'icon-eye-green',
		fileName: 'images/inventory/icn-eye-green.png'
	},
	{
		description: 'eye',
		assetName: 'icon-eye-blue',
		fileName: 'images/inventory/icn-eye-blue.png'
	},
	{
		description: 'eye',
		assetName: 'icon-eye-violet',
		fileName: 'images/inventory/icn-eye-violet.png'
	},
];
