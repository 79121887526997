import styled from "styled-components";
import { GREEN, PRIMARY_COLOR, WHITE } from "../../theme/colors";

export const Header = styled.h2`
  color: ${WHITE};
  font-size: 24px;
  margin: 0;
  margin-bottom: 0.5em;
`;

export const Copy = styled.p`
  font-size: 16px;
  color: ${PRIMARY_COLOR};
  line-height: 1.35;
  margin-bottom: 1em;
`;

export const CallToAction = styled.button`
  border: none;
  color: ${WHITE};
  background-color: ${GREEN};
  font-weight: bold;
  border-radius: 6px;
  padding: 0.75em 1em;
  font-size: 20px;
  cursor: pointer;
`;
