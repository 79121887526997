import Pixi, {Container, Graphics, Text} from 'pixi.js'
import ProgressBar from './ProgressBar'
import BetButton from './BetButton'
import LabelWithValue from './LabelWithValue'
import CONFIG from '../const/config'

const UI_PADDING = 10;

export default class BetParam extends Container{
    constructor(params, parent, app){
        super();
        //console.log(params);
        this.app = app;
        this.maxCharge = params.maxCharge;
        this.type = params.type;
        this.currentLevel = params.initLevel;
        this.label = params.label;
        //this.value = this.label + " : " + this.currentLevel;
        this.value = this.currentLevel;
        this.multiplier = 1;

        this.increase = this.increase.bind(this);
        this.decrease = this.decrease.bind(this);

        let offset = 0;
        this.btnMinus = new BetButton('Minus',this.decrease , this.app);
        this.btnMinus.position = {x: 0, y: 0};
        this.addChild(this.btnMinus);

        offset += this.btnMinus.width + UI_PADDING;
        this.progressBar = new ProgressBar(this.currentLevel, this.maxCharge,  params.color, app);
        this.addChild(this.progressBar);
        let yOffset = this.height/2 - this.progressBar.height/2;
        this.progressBar.position = {x: offset, y: yOffset};

        offset += this.progressBar.width + UI_PADDING;
        this.btnPlus = new BetButton('Plus', this.increase, this.app);
        this.btnPlus.position = {x: offset, y: 0};
        this.addChild(this.btnPlus);

        this.paramLabel = new LabelWithValue(this.label, this.currentLevel);
        this.addChild(this.paramLabel);

        this.updateText();

        /*this.sound = new Howl({
            src: ['sounds/action-level-up-2.wav'],
            //volume: CONFIG.GLOBAL_VOLUME * this.currentLevel/this.maxCharge,
            volume: CONFIG.GLOBAL_VOLUME * 0.5,
            rate: 1
        });*/

        this.updateText = this.updateText.bind(this);

        //DEBUG
        //window['betParam'+this.type] = this;
    }

    increase(){
        this.btnMinus.enable();
        this.currentLevel += 1;
        if (this.currentLevel >= this.maxCharge){
            this.currentLevel = this.maxCharge;
            this.btnPlus.disable(); //
        }
        this.progressBar.showLevel(this.currentLevel);
        this.updateText();
        this.playSound();

        this.parent.emit('state-changed', {type: this.type, level: this.currentLevel});
    }

    setLevel(newLevel){
        this.currentLevel = newLevel > this.maxCharge ? this.maxCharge : newLevel;
        this.progressBar.showLevel(this.currentLevel);
        this.updateText();
    }

    setMultiplier(newMulti){
        this.multiplier = newMulti > 0 ? newMulti : 1;
        this.updateText();
    }

    decrease(){
        //this.sound.play();
        this.btnPlus.enable();
        this.currentLevel -= 1;
        if (this.currentLevel <= 1){
            this.currentLevel = 1;
            this.btnMinus.disable();
        }
        this.progressBar.showLevel(this.currentLevel);
        this.updateText();
        this.playSound();

        this.parent.emit('state-changed', {type: this.type, level: this.currentLevel});
    }

    playSound(){
        //let newVolume = CONFIG.GLOBAL_VOLUME * this.currentLevel/this.maxCharge;
        //this.sound.volume(newVolume);
        //this.sound.play();
    }

    updateText(){
        this.paramLabel.updateText(this.currentLevel * this.multiplier);
        let newX = this.width/2 - this.paramLabel.width / 2,
            newY = - (this.paramLabel.height /2 + 20);
        this.paramLabel.position = {x: newX, y: newY};
    }
}
