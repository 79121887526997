import { loader } from "pixi.js";
export default class AssetLoader {
  constructor(assets, onLoad, onProgress) {
    const len = assets.length;

    for (let i = 0; i < len; i++) {
      const { assetName, fileName } = assets[i];
      loader.add(assetName, fileName);
    }

    loader.load((loader, resources) => {
      onLoad(loader, resources);
    });

    loader.on("progress", (loader, res) => {
      const { progress } = loader;
      if (res.extension !== "json") {
        const { name } = res;
        const loadedFile = assets.find(item => {
          if (item.assetName === res.name) {
            //console.log(item.assetName);
          }
          return item.assetName === res.name;
        });
        if (loadedFile) {
          const { description } = loadedFile;
          onProgress({ progress, description });
          /* console.log(
            `Loaded ${loader.progress.toFixed(2)}% => ${loadedFile.description}`
          );
           */
        }
      }
    });
  }
}
