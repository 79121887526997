import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router";
import { GlobalStyles } from "../../theme/globals";
import { AppContainer, ContentContainer } from "./styles";
import { Header, Footer, PrivateRoute } from "../../components";
import Game from "../Game";
import GetMetamask from "../GetMetamask";
import ActivateMetamask from "../ActivateMetamask";
import Faucet from "../Faucet";

class App extends Component {
  render() {
    const { account } = this.props;
    return (
      <AppContainer>
        <GlobalStyles />
        <Header />
        <ContentContainer>
          <Switch>
            <Route exact path="/get-metamask" component={GetMetamask} />
            <Route
              exact
              path="/activate-metamask"
              component={ActivateMetamask}
            />
            {account && <Route exact path="/faucet" component={Faucet} />}
            <PrivateRoute path="/" component={Game} />
          </Switch>
        </ContentContainer>
        <Footer />
      </AppContainer>
    );
  }
}

const mapStateToProps = state => ({
  account: state.web3.account
}); //

export default connect(mapStateToProps)(withRouter(App));
