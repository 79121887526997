import { postfetchData } from "../../utils/transport";
import { FAUCET_URL } from "./const";

const FAUCET_MAIDEN_FLIGHT = "FAUCET_MAIDEN_FLIGHT";
const MRKT_EMAIL_PROVIDED = "MRKT_EMAIL_PROVIDED";

const FAUCET_PROGRESS = "FAUCET_PROGRESS";
const FAUCET_SUCCESS = "FAUCET_SUCCESS";
const FAUCET_CLAIMED = "FAUCET_CLAIMED";

const maidenFlight = localStorage.getItem(FAUCET_MAIDEN_FLIGHT);
const emailProvided = localStorage.getItem(MRKT_EMAIL_PROVIDED);

export const updateEmail = () => {
  localStorage.setItem(MRKT_EMAIL_PROVIDED, true);
  console.log("Email provided");
};
export const updateMaidenFlight = () => {
  localStorage.setItem(FAUCET_MAIDEN_FLIGHT, true);
  console.log("Maiden Flight completed");
};

export const requestFaucet = (address, color) => async dispatch => {
  dispatch({
    type: FAUCET_PROGRESS
  });
  const payload = {
    address,
    color
  };
  try {
    const response = await postfetchData(FAUCET_URL, payload);
    console.log(response);
    if (response.errorType) {
      dispatch({
        type: FAUCET_CLAIMED
      });
    } else {
      dispatch({
        type: FAUCET_SUCCESS
      });
    }
  } catch (e) {
    console.log("Error during faucet claim");
    console.log(e.message);
  }
};

const initialState = {
  emailProvided: emailProvided === "true",
  maidenFlight: maidenFlight === true,
  faucetProgress: false,
  faucetStatus: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FAUCET_PROGRESS: {
      return {
        ...state,
        faucetProgress: true
      };
    }
    case FAUCET_SUCCESS: {
      return {
        ...state,
        faucetStatus: "success",
        faucetProgress: false
      };
    }
    case FAUCET_CLAIMED: {
      return {
        ...state,
        faucetStatus: "claimed",
        faucetProgress: false
      };
    }
    default: {
      return state;
    }
  }
};
