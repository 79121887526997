import React, { Component } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { Header, Copy } from "../Faucet/style";

class GetMetamask extends Component {
  render() {
    const { walletInstalled } = this.props;
    if (walletInstalled) {
      return <Redirect to={"/"} />;
    }
    return (
      <>
        <Header>Metamask is not installed</Header>
        <Copy>
          Please, go here and install it:
          <a href="https://metamask.io/">Get Metamask for your browser</a>
        </Copy>
      </>
    );
  }
}
const mapStateToProps = state => ({
  walletInstalled: state.web3.walletInstalled
});

export default connect(mapStateToProps)(GetMetamask);
